// src/components/WaifModernCard.tsx

import React, { useEffect, useState } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';

interface WaifModernCardProps {
  playerName: string;
  playerPositions: string;
  statsPlayerCode: string;
}

const WaifModernCard: React.FC<WaifModernCardProps> = ({ playerName, playerPositions, statsPlayerCode }) => {
  const [backgroundImage, setBackgroundImage] = useState<string>(`/images/VictorEvent/${statsPlayerCode} F.jpg`);

  useEffect(() => {
    const loadImage = (src: string) => {
      return new Promise<string>((resolve, reject) => {
        const img = new window.Image();
        img.src = src;
        img.onload = () => resolve(src);
        img.onerror = () => reject(src);
      });
    };

    const fetchImage = async () => {
      try {
        // Intentar cargar la imagen con .jpg
        const jpgSrc = `/images/VictorEvent/${statsPlayerCode} F.jpg`;
        const loadedJpg = await loadImage(jpgSrc);
        setBackgroundImage(`url('${loadedJpg}')`);
      } catch (error) {
        try {
          // Intentar cargar la imagen con .JPG
          const jppSrc = `/images/VictorEvent/${statsPlayerCode} F.JPG`;
          const loadedJpg = await loadImage(jppSrc);
          setBackgroundImage(`url('${loadedJpg}')`);
        } catch (error) {
          // Establecer la imagen por defecto si ambos intentos fallan
          setBackgroundImage(`url('/images/player_user6.jpg')`);
        }
      }
    };

    fetchImage();
  }, [statsPlayerCode]);

  return (
    <Box
      width="338px"
      height="472px"
      border="4px solid #81878a"
      borderRadius="10px"
      position="relative"
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      filter="grayscale(100%)"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      overflow="hidden"
      clipPath="polygon(0 0, 100% 0, 100% 85.2%, 79% 100%, 0 100%, 0 13%)"
    >
      {/* Barra Superior con Logo */}
      <Box
        position="absolute"
        top="0px"
        left="0"
        width="35%"
        height="63px"
        backgroundColor="white"
        clipPath="polygon(0 100%, 60% 100%, 100% 0%, 0 0)"
        display="flex"
        alignItems="center"
        pl={2}
      >
        <Image
          src="/images/StatsLogo.png"
          alt="Logo"
          position="absolute"
          border="4px solid white"
          top="8px"
          left="12px"
          boxSize="48px"
          zIndex={1}
          filter="grayscale(100%)"
        />
      </Box>

      {/* Barra Inferior con ID del Jugador */}
      <Box
        position="absolute"
        bottom="48px"
        left="0"
        width="30%"
        height="50px"
        backgroundColor="#81878a"
        clipPath="polygon(0 100%, 100% 100%, 60% 0, 0 0)"
        display="flex"
        alignItems="center"
        pl={2}
      >
        <Text color="white" fontWeight="bold" fontSize="16px">
          {statsPlayerCode}
        </Text>
      </Box>

      {/* Barra Inferior con Nombre y Posiciones del Jugador */}
      <Box
        position="absolute"
        bottom="-1px"
        left="0"
        width="60%"
        height="50px"
        backgroundColor="#363838"
        clipPath="polygon(0 100%, 100% 100%, 80% 0, 0 0)"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        pl={2}
      >
        <Text color="white" fontWeight="bold" fontSize="14px">
          {playerName}
        </Text>
        <Text color="white" fontWeight="bold" fontSize="12px">
          {playerPositions
            .split(',')
            .map((item) => item.split(' - ')[0].trim())
            .filter((abbr) => abbr !== '')
            .join(', ')}
        </Text>
      </Box>

      {/* Borde Decorativo en la Esquina Inferior Derecha */}
      <Box
        content=""
        position="absolute"
        bottom="-2px"
        right="0"
        width="20%"
        height="14.5%"
        backgroundColor="white"
        borderRadius="55px 0 0 0"
        border="4px solid #81878a"
        borderBottom="none"
        borderRight="none"
      />
    </Box>
  );
};

export default React.memo(WaifModernCard);
