// src/pages/WaifPlayerList.tsx

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  EditIcon,
  SearchIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import { GrRefresh } from "react-icons/gr";
import { LuMoreHorizontal } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";
import PlayerRegistrationModal from "../components/players_registry/PlayerRegistrationModal";
import { Player } from "../types/pages/player";

const PLAYERS_PER_PAGE = 25;

const WaifPlayerList: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen: isRegistrationOpen, onOpen, onClose } = useDisclosure();

  const [players, setPlayers] = useState<Player[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [playerToEdit, setPlayerToEdit] = useState<Player | null>(null); // Estado para el jugador a editar

  useEffect(() => {
    const fetchWaifPlayers = async () => {
      setIsLoading(true);
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          throw new Error("No access token found. Please log in again.");
        }

        const config = {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { status: "Waif" },
        };

        const response = await axiosInstance.get<{
          success: boolean;
          players: Player[];
        }>("/players", config);

        if (response.data.success) {
          setPlayers(response.data.players);
        } else {
          throw new Error("Failed to fetch players.");
        }
      } catch (err: any) {
        console.error("Error fetching players:", err);
        setError(err.message || "An error occurred while fetching players.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWaifPlayers();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredPlayers = players.filter(
    (player) =>
      (player.FIRST_NAME &&
        player.FIRST_NAME.toLowerCase().includes(searchTerm)) ||
      (player.LAST_NAME &&
        player.LAST_NAME.toLowerCase().includes(searchTerm)) ||
      (player.GUARDIAN_EMAIL &&
        player.GUARDIAN_EMAIL.toLowerCase().includes(searchTerm))
  );

  const totalPages = Math.ceil(filteredPlayers.length / PLAYERS_PER_PAGE);
  const paginatedPlayers = filteredPlayers.slice(
    (currentPage - 1) * PLAYERS_PER_PAGE,
    currentPage * PLAYERS_PER_PAGE
  );

  const goToPage = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getAbbreviations = (positions: string | null): string => {
    if (!positions) return "N/A";
    return positions
      .split(", ")
      .map((pos) => pos.split(" -")[0].trim())
      .join(", ");
  };

  const getFullName = (player: Player): string => {
    const { FIRST_NAME, LAST_NAME, MAIDEN_NAME } = player;
    let fullName = `${FIRST_NAME} ${LAST_NAME}`;
    if (MAIDEN_NAME) {
      fullName += ` ${MAIDEN_NAME}`;
    }
    return fullName;
  };

  // Función para añadir un nuevo jugador
  const handleAddPlayer = (newPlayer: Player) => {
    setPlayers((prevPlayers) => [newPlayer, ...prevPlayers]);
  };

  // Función para actualizar un jugador existente
  const handleUpdatePlayer = (updatedPlayer: Player) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) =>
        player.STATS_PLAYER_CODE === updatedPlayer.STATS_PLAYER_CODE ? updatedPlayer : player
      )
    );
  };

  // Función para manejar el clic en el botón Edit
  const handleEditClick = (player: Player) => {
    setPlayerToEdit(player);
    onOpen();
  };

  return (
    <>
      <Box width="100%" p={2} minHeight="100vh">
        <Flex gap={2} alignItems={"center"} mb={{ base: 3, md: 7 }}>
          <Button
            background={"#fff"}
            onClick={() => navigate(-1)}
            width={"40px"}
            height={"40px"}
            borderRadius="md"
            boxShadow="md"
            _hover={{ bg: "gray.100" }}
          >
            <ChevronLeftIcon fontSize={"3xl"} />
          </Button>
          <Heading
            as="h3"
            fontSize={{ base: 18, md: 24, lg: 30, xl: 38 }}
          >
            {"Waif Players List"}
          </Heading>
        </Flex>

        <Flex
          gap={2}
          justify="space-between"
          align={{ base: "end", md: "center" }}
          flexDirection={{ base: "column", sm: "row" }}
          mb={6}
        >
          <InputGroup maxW={"300px"} minW={{ base: "100%", sm: "150px" }}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search by name or email"
              bg={"white"}
              color={"#545560"}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>

          <Flex
            gap={{ base: 1, md: 5 }}
            justify={{ base: "space-between", md: "normal" }}
            width={{ base: "100%", sm: "auto" }}
          >
            <Button
              leftIcon={<SmallAddIcon />}
              backgroundColor={"#1B154B"}
              color={"white"}
              _hover={{ bg: "#1B154B", opacity: 0.8 }}
              onClick={() => {
                setPlayerToEdit(null); // Asegurar que no hay jugador seleccionado
                onOpen();
              }}
            >
              {"Add Player"}
            </Button>

            <Button
              _hover={{ opacity: 0.8 }}
              m={0}
              p={0}
              justifyContent={"center"}
              variant="ghost"
              onClick={() => window.location.reload()}
            >
              <GrRefresh size={25} />
            </Button>

            <Button
              _hover={{ opacity: 0.8 }}
              m={0}
              p={0}
              justifyContent={"center"}
              variant="ghost"
            >
              <LuMoreHorizontal size={30} />
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Flex justify="center" align="center" height="50vh">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : error ? (
          <Flex justify="center" align="center" height="50vh">
            <Text color="red.500">{error}</Text>
          </Flex>
        ) : (
          <Box overflowX="auto">
            <TableContainer
              border="1px solid"
              borderColor="transparent"
              borderRadius="lg"
              maxHeight="680px"
              overflowY="auto"
              maxWidth="100%"
              sx={{
                "::-webkit-scrollbar": {
                  width: "6px",
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: "gray.500",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "blue.700",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "gray.300",
                },
              }}
            >
              <Table
                variant="striped"
                colorScheme="gray"
                sx={{
                  "& tr:nth-of-type(even)": {
                    backgroundColor: "#FFFFFF",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#f2f2f2",
                  },
                  "& th": {
                    backgroundColor: "#f2f2f2",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    color: "#757575",
                    paddingInline: 3,
                    border: "none",
                  },
                  "& td": {
                    textTransform: "capitalize",
                    paddingInline: 3,
                    border: "none",
                  },
                  "& td:not(:last-child)": {
                    fontWeight: "semibold",
                    color: "#232327",
                  },
                  "::-webkit-scrollbar": {
                    width: "6px",
                    },
                    "::-webkit-scrollbar-thumb": {
                    backgroundColor: "gray.500",
                    borderRadius: "8px",
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "blue.700",
                    },
                    "::-webkit-scrollbar-track": {
                    backgroundColor: "gray.300",
                    },
                }}
              >
                <Thead>
                  <Tr>
                    <Th>Player ID</Th>
                    <Th>Full Name</Th>
                    <Th>Birthdate</Th>
                    <Th>Positions</Th>
                    <Th>Uniform No.</Th>
                    <Th>Guardian Email</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody
                  sx={{
                    border: "3px solid transparent",
                    boxShadow: "0 0 0 1px #D0D0E1",
                  }}
                  borderRadius="lg"
                >
                  {filteredPlayers.length > 0 ? (
                    paginatedPlayers.map((player, index) => (
                      <Tr
                        key={player.STATS_PLAYER_CODE}
                        bg={index % 2 === 0 ? "gray.100" : "white"}
                      >
                        <Td>{player.STATS_PLAYER_CODE}</Td>
                        <Td>{getFullName(player)}</Td>
                        <Td>
                            {player.DATE_OF_BIRTH
                            ? new Date(player.DATE_OF_BIRTH).toLocaleDateString('en-US', { timeZone: 'UTC' })
                            : "N/A"}
                        </Td>
                        <Td>{getAbbreviations(player.PLAYING_POSITIONS)}</Td>
                        <Td>{player.UNIFORM_NUMBER || "N/A"}</Td>
                        <Td>{player.GUARDIAN_EMAIL || "N/A"}</Td>
                        <Td>
                          <Button
                            backgroundColor="#38A169"
                            size="sm"
                            _hover={{ opacity: 0.8 }}
                            color="white"
                            leftIcon={<EditIcon />}
                            onClick={() => handleEditClick(player)} // Manejar clic de Edit
                          >
                            Edit
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={7} textAlign="center">
                        No players found with STATUS 'Waif'.
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex justify="center" mt={4} gap={2}>
              <Text
                as="span"
                onClick={() => goToPage(1)}
                cursor={currentPage === 1 ? "not-allowed" : "pointer"}
                color={currentPage === 1 ? "gray.400" : "#2C5282"}
                fontWeight={currentPage === 1 ? "normal" : "bold"}
              >
                {"<<"}
              </Text>
              <Text
                as="span"
                onClick={() => goToPage(currentPage - 1)}
                cursor={currentPage === 1 ? "not-allowed" : "pointer"}
                color={currentPage === 1 ? "gray.400" : "#2C5282"}
                fontWeight={currentPage === 1 ? "normal" : "bold"}
              >
                {"<"}
              </Text>
              {Array.from({ length: totalPages }, (_, i) => (
                <Text
                  as="span"
                  key={i + 1}
                  onClick={() => goToPage(i + 1)}
                  cursor="pointer"
                  color={currentPage === i + 1 ? "#2C5282" : "gray.700"}
                  fontWeight={currentPage === i + 1 ? "bold" : "normal"}
                >
                  {i + 1}
                </Text>
              ))}
              <Text
                as="span"
                onClick={() => goToPage(currentPage + 1)}
                cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
                color={currentPage === totalPages ? "gray.400" : "#2C5282"}
                fontWeight={currentPage === totalPages ? "normal" : "bold"}
              >
                {">"}
              </Text>
              <Text
                as="span"
                onClick={() => goToPage(totalPages)}
                cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
                color={currentPage === totalPages ? "gray.400" : "#2C5282"}
                fontWeight={currentPage === totalPages ? "normal" : "bold"}
              >
                {">>"}
              </Text>
            </Flex>
          </Box>
        )}

        <PlayerRegistrationModal
          openRegistrationModal={isRegistrationOpen}
          toggleRegisterPopup={() => {
            onClose();
            setPlayerToEdit(null); // Limpiar al cerrar el modal
          }}
          onAddPlayer={handleAddPlayer}
          playerToEdit={playerToEdit} // Pasar el jugador a editar
          onUpdatePlayer={handleUpdatePlayer} // Pasar la función para actualizar el jugador
        />
      </Box>
    </>
  );
};

export default WaifPlayerList;
