import React, { useState } from "react";
import { UserList } from "../components/common/UserList";
import { NewUserCreate } from "../components/common/NewUser";
import { Box } from "@chakra-ui/react";

const Accounts: React.FC = () => {
  const [newUser, setNewUser] = useState(false);

  return (
    <>
      {newUser ? (
        <NewUserCreate newUser={newUser} setNewUser={setNewUser} />
      ) : (
        <UserList newUser={newUser} setNewUser={setNewUser} />
      )}
    </>
  );
};



export default Accounts;
