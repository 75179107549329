import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Image,
  Icon,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { RiFileList3Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { mapUserAttributes } from "../utils/helpers";
import { fetchUserInfo } from "../store/authSlice";

const PlayerRegistry = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [user, setUser] = useState<any>(null);
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    const fetchAndStoreUser = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const storedUser = localStorage.getItem("user");

      if (storedUser) {
        const userObject = JSON.parse(storedUser);
        setUser(userObject);
        setRole(userObject["custom:role"] || "");
      } else if (accessToken) {
        try {
          const result = await dispatch(fetchUserInfo({ accessToken })).unwrap();
          if (result?.user?.UserAttributes) {
            const userAttributes = result.user;
            const userObject = mapUserAttributes(userAttributes.UserAttributes);
            if (userObject) {
              localStorage.setItem("user", JSON.stringify(userObject));
              setUser(userObject);
              setRole(userObject["custom:role"] || "");
            } else {
              console.warn("User object is null or undefined.");
            }
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        console.warn("No access token available.");
      }
    };

    fetchAndStoreUser();
  }, [dispatch]);

  return (
    <Box width="100%" maxWidth="1300px" height="100%">
      <Container margin={0} px={6}>
        <Heading as="h1" size="xl" mb={6}>
          Players Registry
        </Heading>
        <SimpleGrid
          templateColumns={{
            base: "1fr",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: 4, md: 6 }}
        >
          {/* Opción siempre visible: Search Players */}
          <NavLink to="/search-players">
            <Box
              _hover={{
                boxShadow: "lg",
                transition: "all .3s",
              }}
              transition={"all .3s"}
              cursor="pointer"
              bg="white"
              p={6}
              width="220px"
              borderRadius="20px"
              border="1px solid #D0D0E1"
            >
              <Text fontSize="lg" fontWeight="bold">
                Search Players
              </Text>
              <Box
                display={"flex"}
                //justifyContent="space-between"
                justifyContent="flex-end"
                width="100%"
                alignItems="right"
              >
                <Icon as={SearchIcon} boxSize={10} color={"custom.grayIcon"} />
                {/*
                <Image
                  alt="Illustration of a baseball player with a bat and helmet"
                  boxSize="70px"
                  src="/images/SearchPlayers.png"
                />
                */}
              </Box>
            </Box>
          </NavLink>

          {/* Opción para Guardian: My Roster */}
          {role === "Guardian" && (
            <NavLink to="/my-roster">
              <Box
                _hover={{
                  boxShadow: "lg",
                  transition: "all .3s",
                }}
                transition={"all .3s"}
                cursor="pointer"
                bg="white"
                p={6}
                width="220px"
                borderRadius="20px"
                border="1px solid #D0D0E1"
              >
                <Text fontSize="lg" fontWeight="bold">
                  My Roster
                </Text>
                <Box
                  display={"flex"}
                  //justifyContent="space-between"
                  justifyContent="flex-end"
                  width="100%"
                  alignItems="center"
                >
                  <Icon
                    as={RiFileList3Line}
                    boxSize={10}
                    color={"custom.grayIcon"}
                  />
                  {/*
                  <Image
                    alt="Illustration of a baseball player with a bat and helmet"
                    boxSize="70px"
                    src="/images/MyRoster.png"
                  />
                  */}
                </Box>
              </Box>
            </NavLink>
          )}
          {/* Opción para Manager: Waif Players List */}
          {role === "Manager" && (
               <NavLink to="/waif-players">
               <Box
                 _hover={{
                   boxShadow: "lg",
                   transition: "all .3s",
                 }}
                 transition={"all .3s"}
                 cursor="pointer"
                 bg="white"
                 p={6}
                 width="220px"
                 borderRadius="20px"
                 border="1px solid #D0D0E1"
               >
                 <Text fontSize="lg" fontWeight="bold">
                   Waif Players List
                 </Text>
                 <Box
                   display={"flex"}
                   //justifyContent="space-between"
                   justifyContent="flex-end"

                   width="100%"
                   alignItems="center"
                 >
                   <Icon
                     as={RiFileList3Line}
                     boxSize={10}
                     color={"custom.grayIcon"}
                   />
                   {/*
                   <Image
                     alt="Illustration of a baseball player with a bat and helmet"
                     boxSize="70px"
                     src="/images/MultiPlayer.png"
                   />
                   */}
                 </Box>
               </Box>
             </NavLink>
          )}       
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default PlayerRegistry;
