import React, { useEffect, useState } from 'react';
import {
    Box,
    CloseButton,
    Flex,
    Heading,
    Modal,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    Spinner,
    Table,
    Tbody,
    Tr,
    Th,
    Td,
    Thead,
} from '@chakra-ui/react';
import axiosInstance from '../../api/axiosConfig';

interface ViewPlayerModalProps {
    openViewPlayer: boolean;
    onClose: () => void;
    statsPlayerCode: string;
}

interface PlayerData {
    FIRST_NAME: string;
    LAST_NAME: string;
    CITY: string;
    STATE: string;
    DATE_OF_BIRTH: string;
    HEIGHT: string;
    WEIGHT: string;
    PLAYING_POSITIONS: string;
    CREATED_AT: string;
    STATUS: string; // Añadido el campo STATUS
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const sinceDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    return `${year}`;
};

// Función para extraer solo las abreviaciones de PLAYING_POSITIONS
const extractAbbreviations = (positions: string | null) => {
    return positions ? positions.split(', ').map(pos => pos.split(' - ')[0]).join(', ') : 'N/A';
};

const ViewPlayerModal: React.FC<ViewPlayerModalProps> = ({ onClose, openViewPlayer, statsPlayerCode }) => {
    const [playerData, setPlayerData] = useState<PlayerData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPlayerData = async () => {
            if (statsPlayerCode && openViewPlayer) {
                setLoading(true);
                try {
                    const response = await axiosInstance.get(`/players/by-id/${statsPlayerCode}`);
                    if (response.data.success && response.data.player) {
                        setPlayerData(response.data.player);
                    } else {
                        console.log("No player data found for this ID");
                    }
                } catch (error) {
                    console.error('Error fetching player data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPlayerData();
    }, [statsPlayerCode, openViewPlayer]);

    const calculateTotals = () => ({
        gp: 188,
        ab: 748,
        r: 154,
        h: 89,
        dbl: 5,
        tpl: 5,
        hr: 12,
        rbi: 115,
        avg: 0.401,
    });

    const totals = calculateTotals();

    return (
        <Modal onClose={onClose} isOpen={openViewPlayer}>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(3px)' />
            <ModalContent my={'auto'} pt={'7px'} pr={'7px'} pb={'10px'} borderRadius={'xl'}>
                <Box display="flex" justifyContent="end">
                    <CloseButton onClick={onClose} />
                </Box>
                <Box paddingInline={6} pb={6}>
                    {loading ? (
                        <Flex justify="center" align="center" height="200px">
                            <Spinner size="xl" color="blue.500" />
                        </Flex>
                    ) : playerData ? (
                        <Flex gap={{ base: 2, md: 4 }} direction={{ base: "column", md: "row" }}>
                            <Box
                                width={{ base: "100%", md: "145px" }}
                                height={{ base: "145px", md: "145px" }}
                                overflow="hidden"
                                background={"#1B154B"}
                                borderRadius={"xl"}
                                filter={playerData.STATUS === 'Waif' ? 'grayscale(100%)' : 'none'}
                            >
                                <img
                                    src={`/images/VictorEvent/${statsPlayerCode} F.jpg`}
                                    alt={`${playerData.FIRST_NAME} ${playerData.LAST_NAME}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    onError={(e) => {
                                        const img = e.currentTarget as HTMLImageElement;
                                        if (img.src.endsWith('.jpg')) {
                                            img.src = img.src.replace('.jpg', '.JPG');
                                        } else {
                                            img.src = '/images/player_user6.jpg';
                                        }
                                    }}
                                    loading="lazy" // Opcional: carga diferida
                                />
                            </Box>
                            <Stack spacing={1}>
                                <Heading fontSize={{ base: "lg", md: "md" }}>
                                    {`${playerData.FIRST_NAME || "N/A"} ${playerData.LAST_NAME || "N/A"}`}
                                </Heading>
                                <Text fontSize={{ base: "sm", md: "sm" }}>
                                    {`${playerData.CITY || "N/A"}, ${playerData.STATE || "N/A"}`}
                                </Text>
                                <Text fontSize={{ base: "sm", md: "sm" }}>
                                    {formatDate(playerData.DATE_OF_BIRTH || "N/A")}
                                </Text>
                                <Flex gap={2}>
                                    <Text fontSize={{ base: "sm", md: "sm" }}>H : {playerData.HEIGHT || "N/A"}</Text>
                                    <Text fontSize={{ base: "sm", md: "sm" }}>W : {playerData.WEIGHT ? `${playerData.WEIGHT} lb` : "N/A"}</Text>
                                </Flex>
                                <Text fontSize={{ base: "sm", md: "sm" }}>
                                    {extractAbbreviations(playerData.PLAYING_POSITIONS)}
                                </Text>
                                <Text fontSize={{ base: "sm", md: "sm" }}>
                                    {sinceDate(playerData.CREATED_AT || "N/A")}
                                </Text>
                            </Stack>
                        </Flex>
                    ) : (
                        <Text>No player data found.</Text>
                    )}
                </Box>

                {/* Career Statistics Section */}
                <Box position="relative" mx={5} mb={4} borderRadius={"20px"} border={"0.5px solid #D0D0E1"}>
                    <Text bg="#D0D0E1" color="#1B154B" p={2} fontSize="md" textAlign="center" fontWeight={"bold"} borderTopRadius={"20px"}>
                        Career Statistics
                    </Text>
                    <Box overflowX="auto" borderBottomRadius={"22px"}>
                        <Table variant="simple" bg="white" borderRadius="lg" width="100%">
                            <Thead bg="#D0D0E1">
                                <Tr>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>Year</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>Team</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>GP</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>AB</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>R</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>H</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>2B</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>3B</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>HR</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>RBI</Th>
                                    <Th color="#797987" fontSize="10px" px={2} py={2}>AVG</Th>
                                </Tr>
                            </Thead>
                            <Tbody bg="#F2F2F2">
                                <Tr>
                                    <Td fontSize="10px" px={1} py={1}>2021</Td>
                                    <Td fontSize="10px" px={1} py={1}>Boston</Td>
                                    <Td fontSize="10px" px={1} py={1}>99</Td>
                                    <Td fontSize="10px" px={1} py={1}>399</Td>
                                    <Td fontSize="10px" px={1} py={1}>87</Td>
                                    <Td fontSize="10px" px={1} py={1}>2</Td>
                                    <Td fontSize="10px" px={1} py={1}>3</Td>
                                    <Td fontSize="10px" px={1} py={1}>5</Td>
                                    <Td fontSize="10px" px={1} py={1}>2</Td>
                                    <Td fontSize="10px" px={1} py={1}>65</Td>
                                    <Td fontSize="10px" px={1} py={1}>.349</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize="10px" px={1} py={1}>2020</Td>
                                    <Td fontSize="10px" px={1} py={1}>Texas</Td>
                                    <Td fontSize="10px" px={1} py={1}>89</Td>
                                    <Td fontSize="10px" px={1} py={1}>349</Td>
                                    <Td fontSize="10px" px={1} py={1}>67</Td>
                                    <Td fontSize="10px" px={1} py={1}>3</Td>
                                    <Td fontSize="10px" px={1} py={1}>2</Td>
                                    <Td fontSize="10px" px={1} py={1}>7</Td>
                                    <Td fontSize="10px" px={1} py={1}>3</Td>
                                    <Td fontSize="10px" px={1} py={1}>50</Td>
                                    <Td fontSize="10px" px={1} py={1}>.453</Td>
                                </Tr>
                                {/* Totals Row */}
                                <Tr fontWeight="bold">
                                    <Td fontSize="10px" px={1} py={1}></Td>
                                    <Td fontSize="10px" px={1} py={1}></Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.gp}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.ab}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.r}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.h}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.dbl}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.tpl}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.hr}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.rbi}</Td>
                                    <Td fontSize="10px" px={1} py={1}>{totals.avg.toFixed(3)}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Box>
            </ModalContent>
        </Modal>
    );
}

export default ViewPlayerModal;
