export const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat("en-US").format(date);
};

export const mapUserAttributes = (
  attributes: { Name: string; Value: string }[]
) => {
  return attributes.reduce((acc: any, attribute) => {
    acc[attribute.Name] = attribute.Value;
    return acc;
  }, {});
};

export const removeQuotes = (str: string): string => {
  // Remove both single and double quotes and trim whitespace
  return str.replace(/['"]/g, "").trim();
};

interface ParsedAddress {
  street: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
}

export const parseAddressString = (addressString: string): ParsedAddress => {
  // Remove any quotes and trim whitespace
  const cleanAddress = addressString.replace(/['"]/g, "").trim();

  // Split by commas and trim each part
  const parts = cleanAddress.split(",").map((part) => part.trim());

  // Default empty address structure
  const defaultAddress: ParsedAddress = {
    street: "",
    unit: "",
    city: "",
    state: "",
    zip: "",
  };

  // Handle case with 5 parts (full address with potential unit)
  // Example: "205 Cll Sheldon, Texas, Mayaguez, Puerto Rico, 00680"
  // Example: "205 dr lassise, , Mayaguez, Puerto Rico, 00680"
  // Example: "Los Paisajes, Calle Marte 2, Gurabo, Puerto Rico, 00778"
  if (parts.length === 5) {
    return {
      street: parts[0] || "", // First part is always street
      unit: parts[1] === "" ? "" : parts[1], // Second part might be empty or unit
      city: parts[2] || "", // Third part is city
      state: parts[3] || "", // Fourth part is state
      zip: parts[4] || "", // Fifth part is zip
    };
  }

  // Handle case with 4 parts (no unit)
  // Example: "PO BOX 593, Ssn Juan, Puerto Rico, 00977"
  if (parts.length === 4) {
    return {
      street: parts[0] || "",
      unit: "",
      city: parts[1] || "",
      state: parts[2] || "",
      zip: parts[3] || "",
    };
  }

  // If format doesn't match expected patterns, return with original first part as street
  console.warn(`Unexpected address format: ${addressString}`);
  return {
    ...defaultAddress,
    street: parts[0] || "", // At least preserve the first part if available
  };
};

// Helper function to format address for display
export const formatAddress = (address: ParsedAddress): string => {
  const parts = [address.street];

  if (address.unit) {
    parts.push(address.unit);
  }

  return parts.join(", ");
};

export const formatDateString = (input: string) => {
  // Remove all non-numeric characters
  const numbers = input.replace(/\D/g, "");

  // Don't format if empty
  if (!numbers) return "";

  // Format as user types
  if (numbers.length <= 2) {
    return numbers;
  } else if (numbers.length <= 4) {
    return `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
  } else {
    return `${numbers.slice(0, 2)}-${numbers.slice(2, 4)}-${numbers.slice(
      4,
      8
    )}`;
  }
};

export const parseAddress = (addressString: string) => {
  // Split the address string by commas and trim each part
  const parts = addressString.split(",").map((part) => part.trim());

  // Ensure there are at least 5 parts, filling missing components with empty strings
  while (parts.length < 5) {
    parts.push("");
  }

  // Map the parts to the respective components
  const [address, address2, city, state, zip] = parts;

  return {
    address,
    address2,
    city,
    state,
    zip,
  };
};

export const removeCountryCode = (
  phoneNumber: string,
  countryPrefix: string
): string => {
  if (phoneNumber.startsWith(countryPrefix)) {
    return phoneNumber.slice(countryPrefix.length);
  }
  return phoneNumber;
};
