import React, { FC } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomPopup from "../common/CustomPopup";

interface ChangePasswordPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (values: { password: string; confirmPassword: string }) => void;
}

const ChangePasswordPopup: FC<ChangePasswordPopupProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  console.log("isOpen", isOpen);
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema,
    onSubmit: (values) => {
      onSave(values);
      onClose();
    },
  });
  //   TODO: Get the right inputs for this

  return (
    <CustomPopup
      isOpen={isOpen}
      onClose={onClose}
      titleText="Change Password"
      bodyElements={
        <Flex direction="column" gap={4}>
          <FormControl
            isInvalid={!!formik.touched.password && !!formik.errors.password}
          >
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword
            }
          >
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
          </FormControl>
        </Flex>
      }
      footerElement={
        <Flex justifyContent="flex-end" gap={4}>
          <Button
            variant="outline"
            onClick={() => {
              onClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={formik.submitForm}
            isDisabled={!formik.isValid || !formik.dirty}
          >
            Save
          </Button>
        </Flex>
      }
    />
  );
};

export default ChangePasswordPopup;
