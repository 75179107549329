import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
  Text,
  Flex,
  Avatar,
} from "@chakra-ui/react";
import { Dispatch, FC, useEffect, useRef, useState } from "react";
import flag1 from "../../assets/flags/tw.png";
import flag2 from "../../assets/flags/fr.png";
import flag3 from "../../assets/flags/nl.png";
import flag4 from "../../assets/flags/us.png";
import avatar from "../../assets/flags/dummyAvatar.png";
import { ChevronDownIcon, TriangleDownIcon } from "@chakra-ui/icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { states } from "../../utils/constants";
import CustomPopup from "../common/CustomPopup";
import PasswordInput from "../common/PasswordInput";
import { parseAddress, removeCountryCode } from "../../utils/helpers";
import ChangePasswordPopup from "./ChangePasswordPopup";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../store/authSlice";

const country = [
  {
    country: "fr",
    flag: flag2,
    prefix: "+11",
  },
  {
    country: "es",
    flag: flag1,
    prefix: "+1",
  },
  {
    country: "us",
    flag: flag4,
    prefix: "+45",
  },
  {
    country: "nl",
    flag: flag3,
    prefix: "+25",
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+$/, "First name should contain only alphabets")
    .required("Please enter first name"),
  lastName: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+$/, "Last name should contain only alphabets")
    .required("Please enter Last name"),
  phone: Yup.number()
    .typeError("Please enter Correct mobile number")
    .test(
      "length",
      "Please enter Correct mobile number",
      (val) => val?.toString()?.length === 10
    )
    .required("Please enter mobile number"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Please enter correct email"
    )
    .required("Please enter email address"),
  password: Yup.string().trim().required("Please enter password"),
  confirmPassword: Yup.string()
    .trim()
    .oneOf(
      [Yup.ref("password")],
      "Please enter Confirm password same as password"
    )
    .required("Please enter Confirm password same as password"),
  address: Yup.string().trim().required("Please enter address"),
  city: Yup.string().trim().required("Please enter city"),
  state: Yup.string().trim().required("Please select state"),
  zipCode: Yup.string()
    .trim()
    .test("length", "Please enter correct zipcode", (val) => {
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val?.toString() ?? "");
    })
    .required("Please enter zipcode"),
});

interface EditProfilePopupProps {
  user: any;
  isProfilePopupOpen: boolean;
  setIsProfilePopupOpen: Dispatch<React.SetStateAction<boolean>>;
}

const EditProfilePopup: FC<EditProfilePopupProps> = ({
  user,
  isProfilePopupOpen,
  setIsProfilePopupOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [number, setNumber] = useState(1);
  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  };
  const originalValuesRef = useRef(initialValues);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("THIS RAN");
      console.log("values", values);
    },
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    submitForm,
    setValues,
    setFieldValue,
    resetForm,
  } = formik;

  const handleSave = async () => {
    setIsSaveDisabled(true); // Temporarily disable button
    values.phone = country[number].prefix + values.phone;
    const profileData = { ...values }; // Prepare form data for API call

    const resultAction = await dispatch(updateProfile(profileData));
    console.log("resultAction", resultAction);
    if (updateProfile.fulfilled.match(resultAction)) {
      // On success, show success state
      setIsSaveSuccessful(true);
      setTimeout(() => {
        setIsSaveSuccessful(false); // Reset success state after a delay
      }, 3000);
    } else {
      console.error("Profile update failed:", resultAction.payload);
    }

    setIsSaveDisabled(false); // Re-enable the button after API call
  };

  useEffect(() => {
    const parsedAddress = parseAddress(user?.address ?? "");
    if (user) {
      const newValues = {
        firstName: user.given_name,
        phone: user.phone_number?.slice(-10),
        email: user.email,
        address: parsedAddress.address,
        lastName: user.family_name,
        password: "",
        confirmPassword: "",
        city: parsedAddress.city || "",
        state: parsedAddress.state || "",
        zipCode: parsedAddress.zip || "",
      };
      // setValues({
      //   firstName: user.given_name,
      //   phone: user.phone_number?.slice(-10),
      //   email: user.email,
      //   address: parsedAddress.address,
      //   lastName: user.family_name,
      //   password: "",
      //   confirmPassword: "",
      //   city: parsedAddress.city || "",
      //   state: parsedAddress.state || "",
      //   zipCode: parsedAddress.zip || "",
      // });
      originalValuesRef.current = newValues;
      // Set form values
      setValues(newValues);
    }
  }, [isProfilePopupOpen, user]);
  // Everytime the formike values change
  useEffect(() => {
    // Compare current form values with cached original values
    const isChanged =
      JSON.stringify(values) !== JSON.stringify(originalValuesRef.current);
    setIsSaveDisabled(!isChanged);
  }, [values]);

  console.log("formik", formik.values);
  console.log("inital", initialValues);

  console.log("values", values);
  return (
    <CustomPopup
      size={{ base: "xs", sm: "md", md: "2xl", xl: "4xl" }}
      titleText={<Text fontSize={{ base: "lg", md: "2xl" }}>Edit Profile</Text>}
      onClose={() => setIsProfilePopupOpen(false)}
      isOpen={isProfilePopupOpen}
      bodyElements={
        <>
          <Flex justifyContent="flex-end">
            <Button
              variant="outline"
              _hover={{ bg: "custom.bg" }}
              borderColor={"transparent"}
              h={12}
              mr={3}
              onClick={() => {
                setIsPasswordPopupOpen(true);
              }}
              minW={{ sm: "40%", md: "200px" }}
            >
              Change Password
            </Button>
          </Flex>
          <Flex
            marginBottom={"10px"}
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              size="xl"
              name={values.firstName}
              bgColor="blue"
              color="white"
            />
          </Flex>
          <Flex
            alignItems="center"
            direction={"column"}
            w={"full"}
            gap={{ base: 2, md: 4, xl: 8 }}
            sx={{
              ".chakra-form-control": {
                ".chakra-input, .chakra-numberinput__field": {
                  boxShadow: "none !important",
                  background: "custom.bg",
                  border: "none",
                  fontWeight: "500",
                  "&:focus": {
                    border: "1px solid",
                    borderColor: "custom.blue",
                  },
                  '&[aria-invalid="true"]': {
                    border: "1px solid",
                    borderColor: "custom.red",
                  },
                },
                ".chakra-form__label": {
                  marginBottom: "5px",
                  color: "custom.gray2",
                  ".chakra-form__required-indicator": {
                    color: "custom.gray2",
                  },
                },
                ".chakra-form__error-message": {
                  marginTop: "5px",
                  color: "custom.red",
                },
                ".chakra-menu__menu-button": {
                  borderWidth: "1px",
                  fontWeight: "500",
                },
                ".chakra-menu__menu-list": {
                  ".chakra-menu__menuitem": {
                    "&:hover": {
                      background: "custom.bg",
                    },
                    "&:focus": {
                      background: "custom.bg",
                    },
                  },
                },
              },
            }}
          >
            <Flex
              w={"full"}
              alignItems="start"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={!!touched.firstName && !!errors.firstName}
              >
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  placeholder="First name"
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  bg={"custom.bg"}
                  h={12}
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!touched.lastName && !!errors.lastName}
              >
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Last name"
                  bg={"custom.bg"}
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  h={12}
                  border={"none"}
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex
              w={"full"}
              alignItems="start"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={!!touched.email && !!errors.email}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  disabled={true}
                  type="email"
                  placeholder="Example1212@gmail.com"
                  bg={"custom.bg"}
                  h={12}
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  border={"none"}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!touched.phone && !!errors.phone}
              >
                <FormLabel>Mobile Number</FormLabel>
                <NumberInput
                  display="flex"
                  position="relative"
                  zIndex={1}
                  bg="custom.bg"
                  h={12}
                  value={removeCountryCode(
                    values.phone,
                    country[number].prefix
                  )}
                >
                  <Box>
                    <Menu>
                      <MenuButton
                        as={Button}
                        w="100%"
                        textAlign="start"
                        rightIcon={
                          <TriangleDownIcon
                            h="12px"
                            w="12px"
                            color="gray.500"
                          />
                        }
                        _focus={{ bg: "custom.bg" }}
                        _hover={{ bg: "custom.bg", border: "none" }}
                        _active={{ bg: "custom.bg" }}
                        sx={{
                          backgroundColor: "custom.bg",
                          border: "none",
                          zIndex: "99999",
                          fontSize: "16px",
                          fontWeight: "400",
                          width: "90px",
                          height: "94%",
                          position: "absolute",
                          top: "1px",
                          left: "2px",
                        }}
                      >
                        <Box
                          cursor={"pointer"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            border: "none",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          <Box>{country[number].prefix}</Box>{" "}
                          <img
                            src={country[number].flag}
                            alt="  "
                            style={{ width: "18px", height: "15px" }}
                          />
                        </Box>
                      </MenuButton>
                      <MenuList>
                        {country.map((opt, index) => (
                          <MenuItem
                            minH="48px"
                            sx={{ display: "flex", gap: "5px" }}
                            key={index}
                            onClick={() => setNumber(index)}
                            _focus={{ bg: "custom.bg" }}
                            _hover={{ bg: "custom.bg" }}
                          >
                            {" "}
                            <Box>
                              {" "}
                              <img
                                src={opt.flag}
                                alt="  "
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Box>
                            <Box>{opt.prefix}</Box>{" "}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>

                  <NumberInputField
                    placeholder="986-468-8432"
                    paddingStart="95px"
                    h={12}
                    border={"none"}
                    sx={{ _placeholder: { color: "custom.gray2" } }}
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </NumberInput>
                <FormErrorMessage>{errors.phone}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex
              w={"full"}
              alignItems="start"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={!!touched.address && !!errors.address}
              >
                <FormLabel>Address</FormLabel>
                <Input
                  type="text"
                  placeholder="House no., Building, Street"
                  bg={"custom.bg"}
                  border={"none"}
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  h={12}
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                />
                <FormErrorMessage>{errors.address}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!touched.zipCode && !!errors.zipCode}
              >
                <FormLabel>Zip code</FormLabel>
                <Input
                  placeholder="000000"
                  bg={"custom.bg"}
                  border={"none"}
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  h={12}
                  name="zipCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipCode}
                />
                <FormErrorMessage>{errors.zipCode}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex
              w={"full"}
              alignItems="start"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={!!touched.city && !!errors.city}
              >
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  placeholder="City"
                  bg={"custom.bg"}
                  sx={{ _placeholder: { color: "custom.gray2" } }}
                  h={12}
                  border={"none"}
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                />
                <FormErrorMessage>{errors.city}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!touched.state && !!errors.state}
              >
                <FormLabel>State</FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    h={12}
                    w="100%"
                    textAlign="start"
                    _active={{ bg: "custom.bg" }}
                    rightIcon={<ChevronDownIcon />}
                    sx={{
                      border: "none",
                      backgroundColor: "custom.bg",
                      fontSize: "16px",
                      fontWeight: "400",
                      "&:hover": {
                        background: "custom.bg",
                      },
                      "+ div": { width: "100%" },
                      "&:focus": { backgroundColor: "custom.bg" },
                    }}
                  >
                    {values.state === "" ? "Select state" : values.state}
                  </MenuButton>
                  <MenuList sx={{ maxH: "250px", overflow: "auto" }}>
                    {states.map((opt, index) => (
                      <MenuItem
                        minH="48px"
                        _hover={{ bg: "custom.bg" }}
                        _focus={{ bg: "custom.bg" }}
                        key={index}
                        onClick={() => setFieldValue("state", opt.label)}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <FormErrorMessage>{errors.state}</FormErrorMessage>
              </FormControl>
            </Flex>
            {/* <Flex
              w={"full"}
              alignItems="center"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={!!touched.password && !!errors.password}
              >
                <FormLabel>Password</FormLabel>
                <PasswordInput
                  h={12}
                  placeholder="Admin@123$"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  sx={{
                    _placeholder: { color: "custom.gray2" },
                    ".chakra-input": {
                      boxShadow: "none !important",
                      background: "custom.bg",
                      border:
                        !!touched.password && !!errors.password ? "" : "none",
                      fontWeight: "500",
                      "&:focus": {
                        border: "1px solid",
                        borderColor: "custom.blue",
                      },
                    },
                    ".chakra-input__right-element": {
                      cursor: "pointer",
                      zIndex: 2,
                      paddingTop: "6px",
                    },
                  }}
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
            </Flex> */}
            {/* <Flex
              w={"full"}
              alignItems="center"
              gap={{ base: 2, md: 4, xl: 8 }}
              direction={{ base: "column", md: "row" }}
            >
              <FormControl
                isRequired
                isInvalid={
                  !!touched.confirmPassword && !!errors.confirmPassword
                }
              >
                <FormLabel>Confirm password</FormLabel>
                <PasswordInput
                  h={12}
                  placeholder="••••••••"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  sx={{
                    _placeholder: { color: "custom.gray2" },
                    ".chakra-input": {
                      boxShadow: "none !important",
                      background: "custom.bg",
                      border:
                        !!touched.confirmPassword && !!errors.confirmPassword
                          ? ""
                          : "none",
                      fontWeight: "500",
                      "&:focus": {
                        border: "1px solid",
                        borderColor: "custom.blue",
                      },
                    },
                    ".chakra-input__right-element": {
                      cursor: "pointer",
                      zIndex: 2,
                      paddingTop: "6px",
                    },
                  }}
                />
                <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
              </FormControl>
            </Flex> */}
          </Flex>
        </>
      }
      footerElement={
        <Flex>
          <Button
            variant="outline"
            _hover={{ bg: "custom.bg" }}
            borderColor={"custom.grayIcon"}
            h={12}
            mr={3}
            onClick={() => {
              setIsProfilePopupOpen(false);
              resetForm();
            }}
            minW={{ sm: "40%", md: "200px" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSaveDisabled}
            bg={isSaveSuccessful ? "green.400" : "custom.blue"}
            _hover={{
              bg: isSaveSuccessful ? "green.500" : "custom.blue",
              opacity: 0.8,
            }}
            h={12}
            minW={{ sm: "40%", md: "200px" }}
            color={"white"}
            onClick={handleSave}
          >
            {isSaveSuccessful ? "Saved!" : "Save"}
          </Button>
          <ChangePasswordPopup
            isOpen={isPasswordPopupOpen}
            onClose={() => setIsPasswordPopupOpen(false)}
            onSave={() => {
              console.log("saved");
            }}
          />
        </Flex>
      }
    />
  );
};

export default EditProfilePopup;
