// src/pages/AnalitysSearchPage.tsx

import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Input, 
  Button, 
  Flex, 
  Text, 
  IconButton, 
  InputLeftElement, 
  InputGroup, 
  Link, 
  useToast,
  useMediaQuery,   // <-- Importamos este hook
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Logo } from '../assets/SVGs/Global'; 
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import axios, { isAxiosError } from 'axios';

const AnalitysSearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  // Detecta si el ancho de pantalla es menor a ~960px (aprox. 10 pulgadas)
  const [isLessThan960] = useMediaQuery('(max-width: 960px)');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.toUpperCase(); // Convertir a mayúsculas automáticamente
    // Permitir solo letras al inicio y luego números
    value = value.replace(/[^A-Z0-9]/g, '');
    // Limitar a 2 letras y 3 números en orden
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    const letters = value.slice(0, 2).replace(/[^A-Z]/g, '');
    const numbers = value.slice(2).replace(/[^0-9]/g, '');
    setSearchQuery(letters + numbers);
  };
  
  const handleSearch = async () => {
    const regex = /^[A-Z]{2}\d{3}$/; // Validación completa para el formato AA000
    if (!regex.test(searchQuery)) {
      toast({
        title: "Invalid Format",
        description: "Please enter a valid Stats Player Code in the format AA000.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Verificar si el jugador existe
      const response = await axiosInstance.get(`/players/by-id/${searchQuery}`);
      
      if (response.status === 200 && response.data.success) {
        navigate(`/player-stats-results/${searchQuery}`);
      } else {
        // Aunque la respuesta no sea 200, consideramos que el jugador no fue encontrado
        toast({
          title: "Player Not Found",
          description: "A player with this code does not exist.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (isAxiosError(error)) { 
        if (error.response) {
          if (error.response.status === 404) {
            toast({
              title: "Player Not Found",
              description: "A player with this code does not exist.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error",
              description: "An unexpected error occurred. Please try again later.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          // Error sin respuesta del servidor
          toast({
            title: "Network Error",
            description: "Unable to reach the server. Please check your internet connection.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Otros tipos de errores
        toast({
          title: "Error",
          description: "An unexpected error occurred. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      console.error('Error fetching player:', error);
    }
  };

  // 1. Mostrar diseño alternativo y mensaje en inglés si pantalla < 960px
  if (isLessThan960) {
    return (
      <Box
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgImage="url('/images/backdrop3.jpg')"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        position="relative"
      >
        {/* Overlay con opacidad 0.7 (claro u oscuro según prefieras) */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(255, 255, 255, 0.4)" 
          zIndex={1}
        />

        <Box 
          bg="white" 
          boxShadow="lg" 
          borderRadius="15px" 
          p={8}  // Menos padding para pantallas pequeñas
          width="90%" 
          maxWidth="400px"
          zIndex={2}
        >
          {/* Alerta en inglés para pantallas pequeñas */}
          <Alert 
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            borderRadius="md"
            boxShadow="md"
            bg="white"
            mb={6}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <Box>
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                Small screen detected!
              </Text>
              <Text fontSize="md">
                We detected that your device screen is smaller than 10 inches.
                This page is best viewed on a larger screen or in landscape mode.
              </Text>
            </Box>
          </Alert>

          <Container textAlign="center" p={2}>
            <Flex justify="center" align="center" mb={4}>
              <IconButton
                aria-label="Redirect to Stats"
                icon={<Logo height={60} width={60} />}
                variant="ghost"
                onClick={() => window.location.href = "https://www.statsdeportes.com"}
                isRound
              />
            </Flex>

            <Text fontSize="xl" fontWeight="bold" mb={8}>
              SPORT ANALYTICS
            </Text>

            <Flex direction="column" align="center" mb={8} width="100%">
              <InputGroup mb={4} width="100%">
                <InputLeftElement pointerEvents="none" height="50px" display="flex" alignItems="center">
                  <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                  height="50px"
                  type="text"
                  placeholder="Enter Stats Player Code"
                  color="#545560"
                  fontSize="18px"
                  bg="white"
                  border="1px solid gray"
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                />
              </InputGroup>

              <Button
                bg="#09264a"
                color="white"
                onClick={handleSearch}
                size="lg"
                width="100%"
                maxWidth="400px"
                _hover={{ bg: '#1B154B', opacity: 0.8 }}
              >
                Search
              </Button>

              <Flex justifyContent="center" mt={6}>
                <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                  <a href="https://www.instagram.com/mystatsdeportes?igsh=MTN2dDBqOTJoM280MA==" target="_blank" rel="noopener noreferrer">
                    <img src="https://imgur.com/IB2lXQH.png" alt="Instagram" style={{ width: '20px', height: '20px' }} />
                  </a>
                </Flex>
                 {/*
                <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <img src="https://imgur.com/RBQB38K.png" alt="Facebook" style={{ width: '20px', height: '20px' }} />
                  </a>
                </Flex>
                <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <img src="https://imgur.com/TmZ8yKC.png" alt="Twitter" style={{ width: '20px', height: '20px', filter: 'invert(100%)' }} />
                  </a>
                </Flex>
                */}
              </Flex>
            </Flex>

            <Box mt={8} textAlign="center" fontSize="sm" color="gray.600">
              <Text>
                Data provided by <strong>StatsDeportes</strong>. This page displays publicly available statistics collected by StatsDeportes about players in various sports.
              </Text>
              <Text>
                For more information, visit{" "}
                <Link href="https://www.statsdeportes.com" color="blue.500" isExternal>
                  StatsDeportes
                </Link>.
              </Text>
            </Box>
          </Container>
        </Box>
      </Box>
    );
  }

  // 2. Si NO es menor a 960px, mostrar el diseño original
  return (
    <Box 
      height="100vh" 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url('/images/backdrop3.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.7, 
        zIndex: -1
      }}
    >
      <Box 
        bg="white" 
        boxShadow="lg" 
        borderRadius="15px" 
        p={16} 
        width="100%" 
        maxWidth="450px"
        minHeight="630px"
        aspectRatio="1 / 1.3"
      >
        <Container textAlign="center" p={2}>
          <Flex justify="center" align="center" mb={8}>
            <IconButton
              aria-label="Redirect to Stats"
              icon={<Logo height={90} width={90} />}
              variant="ghost"
              onClick={() => window.location.href = "https://www.statsdeportes.com"}
              isRound
            />
          </Flex>

          <Text fontSize="2xl" fontWeight="bold" mb={12} mt={16}>
            SPORT ANALYTICS
          </Text>

          <Flex direction="column" align="center" mb={8} width="100%">
            <InputGroup mb={6} width="280px" maxWidth="400px">
              <InputLeftElement pointerEvents="none" height="50px" display="flex" alignItems="center">
                <SearchIcon boxSize={5} />
              </InputLeftElement>
              <Input
                width="100%"
                height="50px"
                type="text"
                placeholder="Enter Stats Player Code"
                color="#545560"
                fontSize="18px"
                bg="white"
                border="1px solid gray"
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
            </InputGroup>

            <Button
              bg="#09264a"
              color="white"
              onClick={handleSearch}
              size="lg"
              width="200px"
              maxWidth="400px"
              _hover={{ bg: '#1B154B', opacity: 0.8 }}
            >
              Search
            </Button>

            <Flex justifyContent="center" mt={6}>
              <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                <a href="https://www.instagram.com/mystatsdeportes?igsh=MTN2dDBqOTJoM280MA==" target="_blank" rel="noopener noreferrer">
                  <img src="https://imgur.com/IB2lXQH.png" alt="Instagram" style={{ width: '20px', height: '20px' }} />
                </a>
              </Flex>
               {/*
              <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                  <img src="https://imgur.com/RBQB38K.png" alt="Facebook" style={{ width: '20px', height: '20px' }} />
                </a>
              </Flex>
             
              <Flex mx={2} bg="#09264a" borderRadius="50%" width="40px" height="40px" justify="center" align="center">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                  <img src="https://imgur.com/TmZ8yKC.png" alt="Twitter" style={{ width: '20px', height: '20px', filter: 'invert(100%)' }} />
                </a>
              </Flex>
              */}
            </Flex>
          </Flex>

          <Box mt={16} textAlign="center" fontSize="sm" color="gray.600" mb="-20px">
            <Text>
              Data provided by <strong>StatsDeportes</strong>. This page displays publicly available statistics collected by StatsDeportes about players in various sports.
            </Text>
            <Text>
              For more information, visit{" "}
              <Link href="https://www.statsdeportes.com" color="blue.500" isExternal>
                StatsDeportes
              </Link>.
            </Text>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AnalitysSearchPage;
