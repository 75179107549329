import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo, getUserInfoByEmail, signIn } from "../store/authSlice";
import { RootState, AppDispatch } from "../store";
import PasswordInput from "../components/common/PasswordInput";
import { Logo } from "../assets/SVGs/Global";
import { mapUserAttributes } from "../utils/helpers";

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [isScout, setIsScout] = useState(false);
  const [password, setPassword] = useState("");

  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("success") === "true" || params.get("role") === "scout") {
      if (params.get("role") === "scout") {
        setIsScout(true);
      }
      onOpen();
    }
  }, [location.search, onOpen]);

  const fetchAndStoreUser = async () => {
    const accessToken = localStorage.getItem("accessToken");
    // const storedUser = localStorage.getItem("user");

    if (accessToken) {
      try {
        const result = await dispatch(fetchUserInfo({ accessToken })).unwrap();
        if (result?.user?.UserAttributes) {
          const userAttributes = result.user;
          const userObject = mapUserAttributes(userAttributes.UserAttributes);
          if (userObject) {
            localStorage.setItem("user", JSON.stringify(userObject));
          } else {
            console.warn("User object is null or undefined.");
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    } else {
      console.warn("No access token available.");
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const resultAction = await dispatch(signIn({ email, password }));
      if (signIn.fulfilled.match(resultAction)) {
        localStorage.setItem("accessToken", resultAction.payload.accessToken);
        const response = await dispatch(getUserInfoByEmail({ email }));
        console.log("response", response);
        fetchAndStoreUser();
        navigate("/news");
      } else {
        throw new Error("Sign-in failed");
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  const signUpHandler = () => {
    navigate("/registration");
  };

  const handleClose = () => {
    if (isScout) {
      setIsScout(false);
    }
    navigate("/");
    onClose();
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="#1C1F2A"
      p={{ base: 4, md: 8 }}
    >
      <Box
        minH={{ base: "auto", md: "600px" }}
        padding={{ base: 4, md: 8 }}
        maxW="md"
        w="full"
        bg={useColorModeValue("white", "gray.700")}
        borderRadius="lg"
        boxShadow="lg"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex justify="space-between" mb={4}>
          <Text fontSize={{ base: "xl", md: "xx-large" }} fontWeight="bold">
            <Logo height={71} width={71} />
          </Text>
          <IconButton
            onClick={() =>
              (window.location.href = "https://www.statsdeportes.com")
            }
            icon={<CloseIcon />}
            size="sm"
            variant="ghost"
            aria-label="Close"
          />
        </Flex>
        <form onSubmit={handleSignIn}>
          <Stack spacing={4}>
            <Text
              fontSize={{ base: "xl", md: "xx-large" }}
              fontWeight="bold"
              textAlign="center"
            >
              Login
            </Text>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <PasswordInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Flex justify="flex-end">
              <Link href="/forgot-password" color="blue.500">
                Forgot Password?
              </Link>
            </Flex>
            <Flex justify="center">
              <Button
                type="submit"
                colorScheme="blue"
                size="md"
                mt={4}
                bg="#1B154B"
                width="150px"
                isLoading={loading}
              >
                Sign In
              </Button>
            </Flex>
            {error && (
              <Text color="red.500" textAlign="center">
                {error}
              </Text>
            )}
          </Stack>
        </form>
        <Box
          mt={8}
          textAlign="center"
          bg="gray.200"
          p={4}
          borderRadius="md"
          w="100%"
        >
          <Text fontSize="lg" fontWeight="bold">
            ⚾️ Baseball
          </Text>
        </Box>
      </Box>

      <Box
        w={{ base: "full", md: "md" }}
        p={{ base: 4, md: 8 }}
        mt={{ base: 4, md: 0 }}
        bg="blue.800"
        color="white"
        borderRadius="lg"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Text fontSize="xl" mb={4} fontWeight="bold">
          Don't Have An Account?
        </Text>
        <Text mb={8}>
          Create an account with us to start tracking players, checking game
          stats, and viewing game schedules for various baseball leagues in PR.
        </Text>
        <Flex justify="center">
          <Button
            colorScheme="blue"
            variant="outline"
            size="md"
            bg="white"
            color="blue.800"
            borderColor="blue.800"
            width="150px"
            onClick={signUpHandler}
          >
            Sign Up
          </Button>
        </Flex>
      </Box>

      <Modal isCentered isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isScout ? "Request Sent!" : "Success!"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {isScout
                ? "Your request for account approval has been sent to the Manager"
                : "Account created successfully!"}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LoginForm;

/*
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../store/authSlice"; // Import the sign-in action
import { RootState, AppDispatch } from "../store"; // Update this path to your store
import PasswordInput from "../components/common/PasswordInput";
import { Logo } from "../assets/SVGs/Global";

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get location to access query params
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [isScout, setIsScout] = useState(false);
  const [password, setPassword] = useState("");

  // Access loading and error from the Redux store
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("success") === "true" || params.get("role") === "scout") {
      if (params.get("role") === "scout") {
        setIsScout(true);
      }
      onOpen(); // Open the modal when success=true is present
    }
  }, [location.search, onOpen]);

  const handleSignIn = async (e: any) => {
    e.preventDefault();

    const resultAction = await dispatch(signIn({ email, password }));
    localStorage.setItem("accessToken", resultAction.payload.accessToken);

    // Check if the signIn action was successful
    if (signIn.fulfilled.match(resultAction)) {
      navigate("/authenticated");
    }
  };

  const signUpHandler = () => {
    navigate("/registration");
  };

  const handleClose = () => {
    // Remove query parameters by navigating to the same path without them
    if (isScout) {
      setIsScout(false);
    }
    navigate("/");
    onClose();
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="#1C1F2A"
      p={{ base: 4, md: 8 }}
    >
      <Box
        minH={{ base: "auto", md: "600px" }}
        padding={{ base: 4, md: 8 }}
        maxW="md"
        w="full"
        bg={useColorModeValue("white", "gray.700")}
        borderRadius="lg"
        boxShadow="lg"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex justify="space-between" mb={4}>
          <Text fontSize={{ base: "xl", md: "xx-large" }} fontWeight="bold">
            <Logo height={71} width={71} />
          </Text>
          <IconButton
            onClick={() =>
              (window.location.href = "https://www.statsdeportes.com")
            }
            icon={<CloseIcon />}
            size="sm"
            variant="ghost"
            aria-label="Close"
          />
        </Flex>
        <form onSubmit={handleSignIn}>
          <Stack spacing={4}>
            <Text
              fontSize={{ base: "xl", md: "xx-large" }}
              fontWeight="bold"
              textAlign="center"
            >
              Login
            </Text>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <PasswordInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isOutline={true}
                sx={{
                  ".chakra-input": {
                    boxShadow: "none !important",
                    background: "custom.bg",
                    border: "none",
                    fontWeight: "500",
                    "&:focus": {
                      border: "1px solid",
                      borderColor: "custom.blue",
                    },
                  },
                  ".chakra-input__right-element": {
                    cursor: "pointer",
                    zIndex: 2,
                  },
                }}
              />
            </FormControl>
            <Flex justify="flex-end">
              <Link href="/forgot-password" color="blue.500">
                Forgot Password?
              </Link>
            </Flex>
            <Flex justify="center">
              <Button
                type="submit"
                colorScheme="blue"
                size="md"
                mt={4}
                bg="#1B154B"
                width="150px"
                isLoading={loading} // Show loading spinner
              >
                Sign In
              </Button>
            </Flex>
            {error && (
              <Text color="red.500" textAlign="center">
                {error}
              </Text>
            )}
          </Stack>
        </form>
        <Box
          mt={8}
          textAlign="center"
          bg="gray.200"
          p={4}
          borderRadius="md"
          w="100%"
        >
          <Text fontSize="lg" fontWeight="bold">
            ⚾️ Baseball
          </Text>
        </Box>
      </Box>

      <Box
        w={{ base: "full", md: "md" }}
        p={{ base: 4, md: 8 }}
        mt={{ base: 4, md: 0 }}
        bg="blue.800"
        color="white"
        borderRadius="lg"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Text fontSize="xl" mb={4} fontWeight="bold">
          Don't Have An Account?
        </Text>
        <Text mb={8}>
          Create an account with us to start tracking players, checking game
          stats, and viewing game schedules for various baseball leagues in PR.
        </Text>
        <Flex justify="center">
          <Button
            colorScheme="blue"
            variant="outline"
            size="md"
            bg="white"
            color="blue.800"
            borderColor="blue.800"
            width="150px"
            onClick={signUpHandler}
          >
            Sign Up
          </Button>
        </Flex>
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isScout ? "Request Sent!" : "Success!"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {isScout
                ? "Your request for account approval has been sent to the Manager"
                : "Account created successfully!"}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LoginForm;
*/
