// src/pages/ScoutAccess.tsx

import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Stat,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react";
import {
  GreenCheck,
  RedCross,
  SearchIcon,
} from "../assets/SVGs/Global";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import AlertPopup from "../components/common/AlertPopup";
import DeniedUserPopup from "../components/account/DeniedUserPopup";
import { acceptScout, denyScout, getScouts } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon as ChakraChevronLeftIcon } from "@chakra-ui/icons";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf, FaHistory } from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";
import { LuMoreHorizontal } from "react-icons/lu";

interface DataItem {
  id: number;
  createdDate: string;
  createdTime: string;
  name: string;
  telephoneNumber: string;
  Email: string;
  Gender: string;
  BirthDate: string;
  Address: string;
  status: string;
}

const ScoutAccess: FC = () => {
  const { scouts, loading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { pendingRequestData, requestHistoryData, isLoading } = useSelector(
    (state: RootState) => state.request
  );

  const navigate = useNavigate();

  const [isRequestView, setIsRequestView] = useState(true);
  const [allData, setAllData] = useState<DataItem[]>([]); 
  const [filteredData, setFilteredData] = useState<DataItem[]>([]); 
  const [isOpenApprovePopup, setIsOpenApprovePopup] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<DataItem>({} as DataItem);
  const [isOpenRequestDetailPopup, setIsOpenRequestDetailPopup] = useState(false);
  const [isOpenDenyPopup, setIsOpenDenyPopup] = useState(false);
  const [resolutionNote, setResolutionNote] = useState("");

  // Variables de Paginación
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10; 
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPage = (page: number) => {
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;
    setCurrentPage(page);
  };

  const toggleApprovePopup = () => setIsOpenApprovePopup(!isOpenApprovePopup);
  const toggleRequestDetailPopup = () => {
    setResolutionNote("");
    setIsOpenRequestDetailPopup(!isOpenRequestDetailPopup);
  };
  const toggleDenyPopup = () => setIsOpenDenyPopup(!isOpenDenyPopup);

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch]);

  useEffect(() => {
    if (scouts && scouts.length > 0) {
      const transformedData = scouts
        .filter((scout) => {
          if (isRequestView) {
            return scout.VERDICT === null;
          }
          return scout.VERDICT !== null;
        })
        .sort((a, b) => new Date(b.CREATED_DATE).getTime() - new Date(a.CREATED_DATE).getTime())
        .map((scout) => ({
          id: scout.SCOUT_REQUEST_ID,
          createdDate: new Date(scout.CREATED_DATE).toLocaleDateString(),
          createdTime: new Date(scout.CREATED_DATE).toLocaleTimeString(),
          name: scout.NAME,
          telephoneNumber: scout.PHONE_NUMBER,
          Email: scout.EMAIL,
          Gender: scout.GENDER,
          BirthDate: new Date(scout.BIRTHDATE).toLocaleDateString(),
          Address: scout.ADDRESS,
          status:
            scout.VERDICT === null
              ? "Pending"
              : scout.VERDICT
              ? "Approved"
              : "Denied",
        }));
      setAllData(transformedData);
      setFilteredData(transformedData);
      setCurrentPage(1); 
    }
  }, [scouts, isRequestView]);

  const handleFilterData = (searchData: string) => {
    if (searchData) {
      const lowercasedSearch = searchData.toLowerCase();
      const filterData = allData.filter((item: DataItem) => {
        return (
          item.name.toLowerCase().includes(lowercasedSearch) ||
          `${item.createdDate.toLowerCase()} ${item.createdTime.toLowerCase()}`.includes(
            lowercasedSearch
          ) ||
          item.telephoneNumber.toLowerCase().includes(lowercasedSearch) ||
          item.Email.toLowerCase().includes(lowercasedSearch) ||
          item.Gender.toLowerCase().includes(lowercasedSearch) ||
          item.BirthDate.toLowerCase().includes(lowercasedSearch) ||
          item.Address.toLowerCase().includes(lowercasedSearch) ||
          (!isRequestView && item.status.toLowerCase().includes(lowercasedSearch))
        );
      });
      setFilteredData(filterData);
      setCurrentPage(1);
    } else {
      setFilteredData(allData);
      setCurrentPage(1);
    }
  };

  const handleApproveRequest = () => {
    dispatch(
      acceptScout({ email: selectedRequest.Email, approverManager: 1 })
    ).then(() => {
      toggleApprovePopup();
      dispatch(getScouts()); 
    });
  };

  const handleAddResolutionNote = () => {
    if (!resolutionNote.trim()) {
      return;
    }

    handleDenyRequest();
  };

  const handleDenyRequest = () => {
    dispatch(
      denyScout({
        email: selectedRequest.Email,
        managerNotes: resolutionNote,
        approverManager: 1,
      })
    ).then(() => {
      toggleDenyPopup();
      dispatch(getScouts()); 
    });
  };

  const renderEmailCell = (email: string) => {
    if (email.length > 23) {
      return (
        <Tooltip label={email} hasArrow>
          <Text>{email.substring(0, 23)}...</Text>
        </Tooltip>
      );
    } else {
      return <Text>{email}</Text>;
    }
  };

  return (
    <Box width="100%" maxWidth="1300px" p={4} minHeight="100vh" bg="transparent">
      {/* Encabezado */}
      <Flex gap={2} alignItems={"center"} mb={{ base: 3, md: 7 }}>
        <Button
          background={"#fff"}
          onClick={() => navigate(-1)}
          width={"40px"}
          height={"40px"}
          borderRadius="md"
          boxShadow="md"
          _hover={{ bg: "gray.100" }}
        >
          <ChakraChevronLeftIcon fontSize={"3xl"} color="#2C5282" />
        </Button>
        <Heading
          as="h3"
          fontSize={{ base: 18, md: 24, lg: 30, xl: 38 }}
        >
          {isRequestView ? "Pending Requests" : "Request History"}
        </Heading>
      </Flex>

      {/* Barra de Acciones */}
      <Flex
        gap={4}
        justify="space-between"
        align={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", sm: "row" }}
        mb={6}
      >
        {/* Campo de Búsqueda */}
        <InputGroup maxW={"300px"} minW={{ base: "100%", sm: "150px" }}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by any field"
            bg={"white"}
            color={"#545560"}
            border="1px solid gray"
            borderRadius="md"
            onChange={(e) => {
              handleFilterData(e.target.value.trimEnd());
            }}
          />
        </InputGroup>

        {/* Botones para Alternar Vistas con Íconos */}
        <Flex
          gap={2}
          justify={{ base: "flex-start", md: "flex-end" }}
          width={{ base: "100%", sm: "auto" }}
          alignItems="center"
        >
          <Button
            leftIcon={isRequestView ? <FaHourglassHalf /> : <FaHistory />}
            backgroundColor={"#1B154B"}
            color={"white"}
            _hover={{ bg: "#1B154B", opacity: 0.8 }}
            onClick={() => {
              setIsRequestView(!isRequestView);
              setFilteredData(
                isRequestView ? requestHistoryData : pendingRequestData
              );
              setCurrentPage(1);
            }}
          >
            {isRequestView ? "Request History" : "Pending Requests"}
          </Button>

          {/* Botón Refrescar */}
          <Tooltip label="Refresh" aria-label="Refresh Tooltip">
            <Button
              _hover={{ opacity: 0.8 }}
              m={0}
              p={0}
              justifyContent={"center"}
              variant="ghost"
              onClick={() => {
                dispatch(getScouts());
              }}
            >
              <GrRefresh size={25} color="#2C5282" />
            </Button>
          </Tooltip>

          {/* Botón Más Opciones */}
          <Tooltip label="More Options" aria-label="More Options Tooltip">
            <Button
              _hover={{ opacity: 0.8 }}
              m={0}
              p={0}
              justifyContent={"center"}
              variant="ghost"
            >
              <LuMoreHorizontal size={30} color="#2C5282" />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>

      {/* Tabla */}
      <Box overflowX="auto">
        <TableContainer 
          border="1px solid"
          borderColor="transparent"
          borderRadius="lg"
          maxHeight="680px"
          overflowY="auto"
          maxWidth="100%"
          sx={{
            "::-webkit-scrollbar": {
              width: "6px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "gray.500",
              borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "blue.700",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "gray.300",
            },
          }}
        >
          <Table
            variant="striped"
            colorScheme="gray"
            sx={{
              "& tr:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f2f2f2",
              },
              "& th": {
                backgroundColor: "#f2f2f2",
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#757575",
                paddingInline: 3,
                border: "none",
              },
              "& td": {
                textTransform: "capitalize",
                paddingInline: 3,
                border: "none",
              },
              "& td:not(:last-child)": {
                fontWeight: "semibold",
                color: "#232327",
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>Created Date</Th>
                <Th>Name</Th>
                <Th>Telephone</Th>
                <Th>Email</Th>
                <Th>Gender</Th>
                <Th>Birthdate</Th>
                <Th>Address</Th>
                <Th>Verdict</Th>
              </Tr>
            </Thead>
            <Tbody
              sx={{
                border: "3px solid transparent",
                boxShadow: "0 0 0 1px #D0D0E1",
              }}
              borderRadius="lg"
            >
              {isLoading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <Tr key={index}>
                    {Array.from({ length: 8 }).map((__, idx) => (
                      <Td key={idx}>
                        <Skeleton height="20px" />
                      </Td>
                    ))}
                  </Tr>
                ))
              ) : (
                currentData?.map((item: DataItem) => (
                  <Tr
                    key={item.id}
                    _hover={{ bg: "gray.200" }}
                    cursor="pointer"
                  >
                    <Td>
                      <Flex direction="column">
                        <Text>{item.createdDate}</Text>
                        <Text>{item.createdTime}</Text>
                      </Flex>
                    </Td>
                    <Td>{item.name}</Td>
                    <Td>{item.telephoneNumber}</Td>
                    <Td>{renderEmailCell(item.Email)}</Td>
                    <Td>{item.Gender}</Td>
                    <Td>{item.BirthDate}</Td>
                    <Td minW="200px" whiteSpace="normal">
                      {item.Address}
                    </Td>
                    {isRequestView ? (
                      <Td>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          gap={2}
                        >
                          <Tooltip label="Approve" aria-label="Approve Tooltip">
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                              onClick={() => {
                                toggleApprovePopup();
                                setSelectedRequest(item);
                              }}
                            >
                              <FaCheckCircle color="green" size={20} aria-label="Approved" />
                            </Button>
                          </Tooltip>

                          <Tooltip label="Deny" aria-label="Deny Tooltip">
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                              onClick={() => {
                                toggleRequestDetailPopup();
                                setSelectedRequest(item);
                              }}
                            >
                              <FaTimesCircle color="red" size={20} />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Td>
                    ) : (
                      <Td>
                        <Flex alignItems="center" gap={2}>
                          {item.status === "Approved" && (
                            <>
                              <FaCheckCircle color="green" size={20} aria-label="Approved" />
                              <Text ml={2} color="green.500">
                                Approved
                              </Text>
                            </>
                          )}
                          {item.status === "Denied" && (
                            <>
                              <FaTimesCircle color="red" size={20} />
                              <Text ml={2} color="red.500">
                                Denied
                              </Text>
                            </>
                          )}
                          {item.status === "Pending" && (
                            <>
                              <FaHourglassHalf color="yellow" size={20} aria-label="Pending" />
                              <Text ml={2} color="yellow.500">
                                Pending
                              </Text>
                            </>
                          )}
                          {!["Approved", "Denied", "Pending"].includes(item.status) && (
                            <Text color="gray.600">{item.status || "N/A"}</Text>
                          )}
                        </Flex>
                      </Td>
                    )}
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Controles de Paginación */}
      <Flex justify="center" mt={4} gap={2}>
        <Text
          as="span"
          onClick={() => goToPage(1)}
          cursor={currentPage === 1 ? "not-allowed" : "pointer"}
          color={currentPage === 1 ? "gray.400" : "#2C5282"}
          fontWeight={currentPage === 1 ? "normal" : "bold"}
        >
          {"<<"}
        </Text>
        <Text
          as="span"
          onClick={() => goToPage(currentPage - 1)}
          cursor={currentPage === 1 ? "not-allowed" : "pointer"}
          color={currentPage === 1 ? "gray.400" : "#2C5282"}
          fontWeight={currentPage === 1 ? "normal" : "bold"}
        >
          {"<"}
        </Text>
        {Array.from({ length: totalPages }, (_, i) => (
          <Text
            as="span"
            key={i + 1}
            onClick={() => goToPage(i + 1)}
            cursor="pointer"
            color={currentPage === i + 1 ? "#2C5282" : "gray.700"}
            fontWeight={currentPage === i + 1 ? "bold" : "normal"}
          >
            {i + 1}
          </Text>
        ))}
        <Text
          as="span"
          onClick={() => goToPage(currentPage + 1)}
          cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
          color={currentPage === totalPages ? "gray.400" : "#2C5282"}
          fontWeight={currentPage === totalPages ? "normal" : "bold"}
        >
          {">"}
        </Text>
        <Text
          as="span"
          onClick={() => goToPage(totalPages)}
          cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
          color={currentPage === totalPages ? "gray.400" : "#2C5282"}
          fontWeight={currentPage === totalPages ? "normal" : "bold"}
        >
          {">>"}
        </Text>
      </Flex>

      {/* Popup de Aprobación */}
      {isOpenApprovePopup && (
        <AlertPopup
          onClose={toggleApprovePopup}
          isOpen={isOpenApprovePopup}
          titleText={"Approved Successfully"}
          alertIcon={<GreenCheck />}
          footerElements={
            <>
              <Button
                bgColor={"#1B154B"}
                variant={"solid"}
                colorScheme="darkpurple"
                color={"white"}
                h={12}
                w="210px"
                isLoading={isLoading}
                onClick={handleApproveRequest}
              >
                Ok
              </Button>
            </>
          }
          description="Payment will be charged and users will be informed."
        />
      )}

      {/* Popup de Denegación */}
      {isOpenDenyPopup && (
        <AlertPopup
          onClose={toggleDenyPopup}
          isOpen={isOpenDenyPopup}
          titleText={"Denied Request"}
          alertIcon={<RedCross />}
          footerElements={
            <>
              <Button
                bgColor={"#1B154B"}
                variant={"solid"}
                colorScheme="darkpurple"
                color={"white"}
                h={12}
                w="210px"
                isLoading={isLoading}
                onClick={() => {
                  toggleDenyPopup();
                  toggleRequestDetailPopup();
                }}
              >
                Ok
              </Button>
            </>
          }
          description="Your decision has been successfully submitted. Please note that the user's payment will not be charged."
        />
      )}

      {/* Popup para Agregar Nota de Denegación */}
      <DeniedUserPopup
        isLoading={isLoading}
        resolutionNote={resolutionNote}
        selectedRequest={selectedRequest}
        isOpenRequestDetailPopup={isOpenRequestDetailPopup}
        setResolutionNote={setResolutionNote}
        toggleRequestDetailPopup={toggleRequestDetailPopup}
        handleAddResolutionNote={handleAddResolutionNote}
      />
    </Box>
  );
};

export default ScoutAccess;
