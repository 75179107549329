// src/components/common/UserList.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spinner,
  Skeleton,
  useDisclosure,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  EditIcon,
  SearchIcon,
  SmallAddIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";

import { GrRefresh } from "react-icons/gr";
import { LuMoreHorizontal } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchUsers } from "../../store/authSlice";
import { parseAddressString } from "../../utils/helpers";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

interface User {
  ID: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL: string;
  ROLE: string;
  ADDRESS: string;
  ACTIVE: boolean;
}

interface UserListProps {
  newUser: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserList = ({
  setNewUser,
  newUser,
}: UserListProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { users = [], loading, error } = useSelector(
    (state: RootState) => state.auth
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [roleFilter, setRoleFilter] = useState<string>("All");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const USERS_PER_PAGE = 25;

  const {onOpen: onAddUserOpen} = useDisclosure();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    let filtered = users;

    // Aplicar filtro por rol
    if (roleFilter === "Manager") {
      filtered = filtered.filter((user) => user.ROLE === "Manager");
    } else if (roleFilter === "Customer") {
      filtered = filtered.filter(
        (user) => user.ROLE === "Guardian" || user.ROLE === "Scout"
      );
    }

    // Aplicar filtro de búsqueda
    if (searchTerm.trim() !== "") {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((user) =>
        Object.values(user)
          .join(" ")
          .toLowerCase()
          .includes(lowercasedTerm)
      );
    }

    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [searchTerm, roleFilter, users]);

  const totalPages = Math.ceil(filteredUsers.length / USERS_PER_PAGE);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * USERS_PER_PAGE,
    currentPage * USERS_PER_PAGE
  );

  const goToPage = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getFullName = (user: User): string => {
    return `${user.FIRST_NAME} ${user.LAST_NAME}`;
  };

  const handleRefresh = () => {
    dispatch(fetchUsers());
  };

  return (
    <Box width="100%" maxWidth="1300px" p={4} minHeight="100vh" bg="transparent">
        <Flex gap={2} alignItems={"center"} mb={{ base: 3, md: 7 }}>
        <Button
          background={"#fff"}
          onClick={() => navigate(-1)}
          width={"40px"}
          height={"40px"}
          borderRadius="md"
          boxShadow="md"
          _hover={{ bg: "gray.100" }}
        >
          <ChevronLeftIcon fontSize={"3xl"} color="#2C5282" />
        </Button>
        <Heading
          as="h3"
          fontSize={{ base: 18, md: 24, lg: 30, xl: 38 }}
        >
          Users List
        </Heading>
      </Flex>

      {/* Barra de Acciones */}
      <Flex
        gap={4}
        justify="space-between"
        align={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", sm: "row" }}
        mb={6}
      >
        {/* Campo de Búsqueda */}
        <InputGroup maxW={"300px"} minW={{ base: "100%", sm: "150px" }}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by any field"
            bg={"white"}
            color={"#545560"}
            border="1px solid gray"
            borderRadius="md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        {/* Botones de Filtro y Acción */}
        <Flex
          gap={2}
          justify={{ base: "flex-start", md: "flex-end" }}
          width={{ base: "100%", sm: "auto" }}
          alignItems="center"
          overflowX="auto" // Añadido para permitir desplazamiento horizontal si es necesario
        >
          {/* Botones de Filtro */}
          <Flex gap={2}>
            <Button
              colorScheme="blue"
              variant={roleFilter === "All" ? "solid" : "outline"}
              backgroundColor={roleFilter === "All" ? "blue.500" : "white"}
              borderColor={roleFilter === "All" ? "blue.500" : "gray.200"}
              onClick={() => setRoleFilter("All")}
            >
              All
            </Button>
            <Button
              colorScheme="blue"
              variant={roleFilter === "Manager" ? "solid" : "outline"}
              backgroundColor={roleFilter === "Manager" ? "blue.500" : "white"}
              borderColor={roleFilter === "Manager" ? "blue.500" : "gray.200"}
              onClick={() => setRoleFilter("Manager")}
            >
              Manager
            </Button>
            <Button
              colorScheme="blue"
              variant={roleFilter === "Customer" ? "solid" : "outline"}
              backgroundColor={roleFilter === "Customer" ? "blue.500" : "white"}
              borderColor={roleFilter === "Customer" ? "blue.500" : "gray.200"}
              onClick={() => setRoleFilter("Customer")}
            >
              Customer
            </Button>
          </Flex>

          {/* Botones de Acción */}
          <Flex
            gap={2}
            alignItems="center"
          >
            {/* Botón Añadir Usuario */}
            <Button
              leftIcon={<SmallAddIcon />}
              backgroundColor={"#1B154B"}
              color={"white"}
              _hover={{ bg: "#1B154B", opacity: 0.8 }}
              onClick={() => {
                setNewUser(!newUser);
                onAddUserOpen();
              }}
            >
              Add User
            </Button>

            {/* Botón Refrescar */}
            <Tooltip label="Refresh" aria-label="Refresh Tooltip">
              <Button
                _hover={{ opacity: 0.8 }}
                m={0}
                p={0}
                justifyContent={"center"}
                variant="ghost"
                onClick={handleRefresh}
              >
                <GrRefresh size={25} color="#2C5282" />
              </Button>
            </Tooltip>

            {/* Botón Más Opciones */}
            <Tooltip label="More Options" aria-label="More Options Tooltip">
              <Button
                _hover={{ opacity: 0.8 }}
                m={0}
                p={0}
                justifyContent={"center"}
                variant="ghost"
              >
                <LuMoreHorizontal size={30} color="#2C5282" />
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>

      {/* Tabla de Usuarios */}
      <Box overflowX="auto">
        <TableContainer
          border="1px solid"
          borderColor="transparent"
          borderRadius="lg"
          maxHeight="680px"
          overflowY="auto"
          maxWidth="100%"
          sx={{
            "::-webkit-scrollbar": {
              width: "6px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "gray.500",
              borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "blue.700",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "gray.300",
            },
          }}
        >
          <Table
            variant="striped"
            colorScheme="gray"
            sx={{
              "& thead th": {
                position: "sticky",
                top: 0,
                backgroundColor: "#f2f2f2",
                zIndex: 1,
              },
              "& tr:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f2f2f2",
              },
              "& th": {
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#757575",
                paddingInline: 3,
                border: "none",
              },
              "& td": {
                textTransform: "capitalize",
                paddingInline: 3,
                border: "none",
              },
              "& td:not(:last-child)": {
                fontWeight: "semibold",
                color: "#232327",
              },
              "::-webkit-scrollbar": {
                width: "6px",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "gray.500",
                borderRadius: "8px",
              },
              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "blue.700",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "gray.300",
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Tier</Th>
                <Th>Address</Th>
                <Th>City</Th>
                <Th>State</Th>
                <Th>Country</Th>
                <Th>Zip Code</Th>
                <Th>Active</Th>
              </Tr>
            </Thead>
            <Tbody
              sx={{
                border: "3px solid transparent",
                boxShadow: "0 0 0 1px #D0D0E1",
              }}
              borderRadius="lg"
            >
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <Tr key={index}>
                    {Array.from({ length: 11 }).map((__, idx) => (
                      <Td key={idx}>
                        <Skeleton height="20px" />
                      </Td>
                    ))}
                  </Tr>
                ))
              ) : error ? (
                <Tr>
                  <Td colSpan={11} textAlign="center" color="red.500">
                    {error}
                  </Td>
                </Tr>
              ) : filteredUsers.length > 0 ? (
                paginatedUsers.map((user, index) => (
                  <Tr
                    key={user.ID}
                    _hover={{ bg: "gray.200" }}
                    cursor="pointer"
                  >
                    <Td>{user.ID}</Td>
                    <Td>{getFullName(user)}</Td>
                    <Td>{user.EMAIL}</Td>
                    <Td>{user.ROLE}</Td>
                    <Td>Subscription</Td>
                    <Td
                      maxWidth="200px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {parseAddressString(user.ADDRESS).street || "N/A"}
                    </Td>
                    <Td>{parseAddressString(user.ADDRESS).city || "N/A"}</Td>
                    <Td>{parseAddressString(user.ADDRESS).state || "N/A"}</Td>
                    <Td>USA</Td>
                    <Td>{parseAddressString(user.ADDRESS).zip || "N/A"}</Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {!user.ACTIVE ? (
                          <Tooltip label="Active" aria-label="Active Tooltip">
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                            >
                              <FaCheckCircle color="green" size={20} />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip label="Inactive" aria-label="Inactive Tooltip">
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                            >
                              <FaTimesCircle color="red" size={20} />
                            </Button>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11} textAlign="center">
                    No users found.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Controles de Paginación */}
        <Flex justify="center" mt={4} gap={2} overflowX="auto">
          <Text
            as="span"
            onClick={() => goToPage(1)}
            cursor={currentPage === 1 ? "not-allowed" : "pointer"}
            color={currentPage === 1 ? "gray.400" : "#2C5282"}
            fontWeight={currentPage === 1 ? "normal" : "bold"}
          >
            {"<<"}
          </Text>
          <Text
            as="span"
            onClick={() => goToPage(currentPage - 1)}
            cursor={currentPage === 1 ? "not-allowed" : "pointer"}
            color={currentPage === 1 ? "gray.400" : "#2C5282"}
            fontWeight={currentPage === 1 ? "normal" : "bold"}
          >
            {"<"}
          </Text>
          {Array.from({ length: totalPages }, (_, i) => (
            <Text
              as="span"
              key={i + 1}
              onClick={() => goToPage(i + 1)}
              cursor="pointer"
              color={currentPage === i + 1 ? "#2C5282" : "gray.700"}
              fontWeight={currentPage === i + 1 ? "bold" : "normal"}
            >
              {i + 1}
            </Text>
          ))}
          <Text
            as="span"
            onClick={() => goToPage(currentPage + 1)}
            cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
            color={currentPage === totalPages ? "gray.400" : "#2C5282"}
            fontWeight={currentPage === totalPages ? "normal" : "bold"}
          >
            {">"}
          </Text>
          <Text
            as="span"
            onClick={() => goToPage(totalPages)}
            cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
            color={currentPage === totalPages ? "gray.400" : "#2C5282"}
            fontWeight={currentPage === totalPages ? "normal" : "bold"}
          >
            {">>"}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default UserList;
