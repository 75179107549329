// src/pages/PlayerStatsPage.tsx

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Text, 
  Flex, 
  Image, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Grid, 
  GridItem, 
  Icon, 
  Button, 
  Heading, 
  IconButton,
  Spinner,
  useToast,
  useMediaQuery,
  Alert,
  AlertIcon,
  Tooltip  // <--- Importamos Tooltip
} from '@chakra-ui/react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { IoShirtOutline } from "react-icons/io5";
import Select from 'react-select';
import { Logo } from '../assets/SVGs/Global'; 

import axiosInstance from '../api/axiosConfig'; 
import axios, { isAxiosError } from 'axios';

// Definición de tipos para mayor claridad y seguridad
interface PlayerInfo {
  firstName: string;
  initial?: string;
  lastName: string;
  maidenName?: string;
  uniformNumber: number;
  position: string;
  age: number;
  profileImage: string;
  statsCode: string;
  countryCode: string;
}

interface PlayerStatsPageParams extends Record<string, string | undefined> {
  statsCode: string;
}

interface BattingStats {
  PA: number;
  AB: number;
  R: number;
  H: number;
  '1B': number;
  '2B': number;
  '3B': number;
  HR: number;
  RBI: number;
  BB: number;
  BBI: number;
  KC: number;
  KS: number;
  SO: number;
  HBP: number;
  SB: number;
  CS: number;
  SCB: number;
  SF: number;
  CI: number;
  GDP: number;
  BA: number;
  OBP: number;
  SLG: number;
  AVG: number;
  OPS: number;
}

interface PitchingStats {
  IP: number;
  BF: number;
  R: number;
  ER: number;
  K: number;
  H: number;
  BB: number;
  G: number;
  SV: number;
  STR: number;
  BALL: number;
  IBB: number;
  HB: number;
  BK: number;
  WP: number;
  CI: number;
  SCB: number;
  SCF: number;
  '1B': number;
  '2B': number;
  '3B': number;
  HR: number;
  ERA: number;
  W: number;
  L: number;
  SO: number;
  WHIP: number;
  pitchDistribution: {
    Fastball: number;
    Curveball: number;
    Slider: number;
    Changeup: number;
  };
}

interface FieldingStats {
  G: number;
  ET: number;
  EF: number;
  ERR: number;
  PO: number;
  A: number;
  SBA: number;
  CS: number;
  DP: number;
  PB: number;
  PK: number;
}

interface Measures {
  team: string;
  yards: string;
  popTime: string;
  sixtyFeet: string;
  velocity: {
    IF: string;
    OF: string;
    C: string;
  };
}

// Nueva interfaz para el Ranking
interface Ranking {
  yards: string;        
  popTime: string;      
  sixtyFeet: string;    
  velocityIF: string;   
  velocityOF: string;   
  velocityC: string;    
}

interface SyntheticStats {
  pitcherStats: { inning: string; mph: string[] }[];
}

// Componente TeamDisplay
const TeamDisplay: React.FC<{ team: string | undefined }> = ({ team }) => {
  // Función para obtener los estilos según el equipo
  const getTeamStyles = (team: string) => {
    switch (team) {
      case 'Amarillo':
        return { borderColor: 'yellow', backgroundColor: 'yellow', color: 'black' };
      case 'Azul Navy':
        return { borderColor: '#001F3F', backgroundColor: '#001F3F', color: 'white' };
      case 'Baby Blue':
        return { borderColor: '#ADD8E6', backgroundColor: '#ADD8E6', color: 'black' };
      case 'Blanco':
        return { borderColor: 'black', backgroundColor: 'white', color: 'black' };
      default:
        return { borderColor: 'gray.300', backgroundColor: 'transparent', color: 'inherit' };
    }
  };

  const teamName = team || 'N/A';
  const styles = getTeamStyles(teamName);

  return (
    <Text fontSize="sm" color="gray.600">
      <Text as="span" fontSize='md' fontWeight="bold">
        Team:
      </Text>
      {' '}
      <Text
        as="span"
        border="1px solid"
        borderColor={styles.borderColor}
        backgroundColor={styles.backgroundColor}
        color={styles.color}
        padding="2px 4px"
        mt="-2px"
        borderRadius="4px"
        display="inline-block"
        fontFamily="inherit"
      >
        {teamName}
      </Text>
    </Text>
  );
};

const PlayerStatsPage = () => {
  const { statsCode } = useParams<PlayerStatsPageParams>();
  const navigate = useNavigate();
  const toast = useToast();

  // Hook para detectar si el ancho de pantalla es menor a ~960px
  const [isLessThan960] = useMediaQuery('(max-width: 960px)');

  const [playerInfo, setPlayerInfo] = useState<PlayerInfo | null>(null);
  const [battingStats, setBattingStats] = useState<BattingStats | null>(null);
  const [pitchingStats, setPitchingStats] = useState<PitchingStats | null>(null);
  const [fieldingStats, setFieldingStats] = useState<FieldingStats | null>(null);
  const [measures, setMeasures] = useState<Measures | null>(null);
  const [ranking, setRanking] = useState<Ranking | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [showPitcherTable, setShowPitcherTable] = useState<boolean>(false);
  const [statsType, setStatsType] = useState<'Batting' | 'Pitching' | 'Fielding'>('Batting');
  const [availableStatsTypes, setAvailableStatsTypes] = useState<string[]>([]);

  // --- MODIFICADO PARA FLIP DE IMAGEN ---
  const [isFrontImage, setIsFrontImage] = useState(true);

  const handleImageClick = () => {
    if (isFrontImage) {
      setIsFrontImage(false);
      setTimeout(() => {
        setIsFrontImage(true);
      }, 2000);
    }
  };

  // Opciones para el selector de tipo de estadísticas
  const options = [
    { value: 'Batting', label: 'Batting' },
    { value: 'Pitching', label: 'Pitching' },
    { value: 'Fielding', label: 'Fielding' }
  ];

  const customSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: 'gray.100',
      '&:hover': { borderColor: 'gray.100' },
      cursor: 'pointer',
      minWidth: '150px'
    }),
    menu: (provided: any) => ({
      ...provided,
      borderColor: 'gray.100',
      backgroundColor: 'white', 
    }),
    option: (provided: any, state: { isFocused: any; }) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#2C5282' : 'white', 
      color: state.isFocused ? 'white' : '#2C5282',
      cursor: 'pointer',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#2C5282',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#2C5282',
      fontWeight: 'bold'
    })
  };

  const getFullName = (): string => {
    if (!playerInfo) return '';
    const { firstName, initial, lastName, maidenName } = playerInfo;
    let fullName = firstName;
    if (initial) fullName += ` ${initial}`;
    fullName += ` ${lastName}`;
    if (maidenName) fullName += ` ${maidenName}`;
    return fullName;
  };

  const calculateAge = (dateOfBirth: string | null) => {
    if (!dateOfBirth) return 0;
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Datos sintéticos para pitcherStats (mantiene datos ficticios)
  const syntheticStats: SyntheticStats = {
    pitcherStats: [
      { inning: '1st', mph: ['+ 55 mph', '+ 66 mph', '+ 77 mph'] },
      { inning: '2nd', mph: ['+ 55 mph', '+ 66 mph', '+ 77 mph'] },
      { inning: '3rd', mph: ['+ 55 mph', '+ 66 mph', '+ 77 mph'] },
    ]
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        if (!statsCode) {
          throw new Error('No se proporcionó un código de estadísticas.');
        }

        console.log("statsCode recibido desde useParams:", statsCode);

        // Obtener información personal del jugador
        const playerResponse = await axiosInstance.get(`/players/by-id/${statsCode}`);
        if (playerResponse.status === 200 && playerResponse.data.success) {
          const data = playerResponse.data.player;
          const info: PlayerInfo = {
            firstName: data.FIRST_NAME,
            initial: data.INITIAL || '',
            lastName: data.LAST_NAME,
            maidenName: data.MAIDEN_NAME || '',
            uniformNumber: data.UNIFORM_NUMBER,                             
            position: data.PLAYING_POSITIONS.split(', ').map((pos: string) => pos.split(' - ')[0]).join(', ') || 'N/A',
            age: data.DATE_OF_BIRTH ? calculateAge(data.DATE_OF_BIRTH) : 0,
            profileImage: data.IMG || '/images/player_user6.jpg',
            statsCode: data.STATS_PLAYER_CODE || statsCode || '',
            countryCode: data.STATE || 'PR'
          };
          setPlayerInfo(info);

          const availableTypes: string[] = [];

          // Bateo
          try {
            const battingResponse = await axiosInstance.get(`/players/by-id/${info.statsCode}/batting`);
            if (battingResponse.status === 200 && battingResponse.data.success) {
              setBattingStats(battingResponse.data.battingStats);
              availableTypes.push('Batting');
            } else {
              setBattingStats(null);
            }
          } catch (battingError) {
            console.error('Error fetching batting stats:', battingError);
            setBattingStats(null);
          }

          // Pitcheo
          try {
            const pitchingResponse = await axiosInstance.get(`/players/by-id/${info.statsCode}/pitching`);
            if (pitchingResponse.status === 200 && pitchingResponse.data.success) {
              setPitchingStats(pitchingResponse.data.pitchingStats);
              availableTypes.push('Pitching');
            } else {
              setPitchingStats(null);
            }
          } catch (pitchingError) {
            console.error('Error fetching pitching stats:', pitchingError);
            setPitchingStats(null);
          }

          // Fildeo
          try {
            const fieldingResponse = await axiosInstance.get(`/players/by-id/${info.statsCode}/fielding`);
            if (fieldingResponse.status === 200 && fieldingResponse.data.success) {
              setFieldingStats(fieldingResponse.data.fieldingStats);
              availableTypes.push('Fielding');
            } else {
              setFieldingStats(null);
            }
          } catch (fieldingError) {
            console.error('Error fetching fielding stats:', fieldingError);
            setFieldingStats(null);
          }

          // Medidas adicionales
          try {
            const measuresResponse = await axiosInstance.get(`/players/by-id/${info.statsCode}/measures`);
            if (measuresResponse.status === 200 && measuresResponse.data.success) {
              setMeasures(measuresResponse.data.measures);
            } else {
              setMeasures(null);
            }
          } catch (measuresError) {
            console.error('Error fetching measures:', measuresError);
            setMeasures(null);
          }

          // Ranking
          try {
            const rankingResponse = await axiosInstance.get(`/players/by-id/${info.statsCode}/ranking`);
            if (rankingResponse.status === 200 && rankingResponse.data.success) {
              setRanking(rankingResponse.data.ranking);
            } else {
              setRanking(null);
            }
          } catch (rankingError) {
            console.error('Error fetching ranking:', rankingError);
            setRanking(null);
          }

          // Ajustar según tipos disponibles
          setAvailableStatsTypes(availableTypes);
          if (!availableTypes.includes(statsType)) {
            setStatsType(availableTypes[0] as 'Batting' | 'Pitching' | 'Fielding');
          }

          setLoading(false);
        } else {
          throw new Error('Jugador no encontrado.');
        }
      } catch (err: any) {
        setLoading(false);
        setError(err.message || 'Error al obtener los datos del jugador.');
        console.error('Error fetching player data:', err);
        toast({
          title: "Error",
          description: err.message || "No se pudieron obtener los datos del jugador.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      }
    };

    fetchPlayerData();
  }, [statsCode, navigate, toast, statsType]);

  // Función para renderizar la tabla de estadísticas basada en el tipo
  const renderStatsTable = () => {
    if (statsType === 'Batting' && battingStats) {
      const bs = battingStats;
      return (
        <Table variant="simple">
          <Thead>
            <Tr bg="gray.100">
              <Tooltip label="At Bats" hasArrow>
                <Th>AB</Th>
              </Tooltip>
              <Tooltip label="Runs" hasArrow>
                <Th>R</Th>
              </Tooltip>
              <Tooltip label="Singles" hasArrow>
                <Th>1B</Th>
              </Tooltip>
              <Tooltip label="Doubles" hasArrow>
                <Th>2B</Th>
              </Tooltip>
              <Tooltip label="Triples" hasArrow>
                <Th>3B</Th>
              </Tooltip>
              <Tooltip label="Homeruns" hasArrow>
                <Th>HR</Th>
              </Tooltip>
              <Tooltip label="Runs Batted In" hasArrow>
                <Th>RBI</Th>
              </Tooltip>
              <Tooltip label="Batting Average" hasArrow>
                <Th>AVG</Th>
              </Tooltip>
              <Tooltip label="Walks" hasArrow>
                <Th>BB</Th>
              </Tooltip>
              <Tooltip label="On Base Percentage" hasArrow>
                <Th>OBP</Th>
              </Tooltip>
              <Tooltip label="Slugging Percentage" hasArrow>
                <Th>SLG</Th>
              </Tooltip>
              <Tooltip label="On Base Plus Slugging" hasArrow>
                <Th>OPS</Th>
              </Tooltip>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{bs.AB ?? 'N/A'}</Td>
              <Td>{bs.R ?? 'N/A'}</Td>
              <Td>{bs['1B'] ?? 'N/A'}</Td>
              <Td>{bs['2B'] ?? 'N/A'}</Td>
              <Td>{bs['3B'] ?? 'N/A'}</Td>
              <Td>{bs.HR ?? 'N/A'}</Td>
              <Td>{bs.RBI ?? 'N/A'}</Td>
              <Td>
                {bs.AVG !== undefined && bs.AVG !== null
                  ? Number(bs.AVG).toFixed(3)
                  : 'N/A'}
              </Td>              
              <Td>{bs.BB ?? 'N/A'}</Td>
              <Td>
                {bs.OBP !== undefined && bs.OBP !== null
                  ? Number(bs.OBP).toFixed(3)
                  : 'N/A'}
              </Td>  
              <Td>
                {bs.SLG !== undefined && bs.SLG !== null
                  ? Number(bs.SLG).toFixed(3)
                  : 'N/A'}
              </Td>  
              <Td>
                {bs.OPS !== undefined && bs.OPS !== null
                  ? Number(bs.OPS).toFixed(3)
                  : 'N/A'}
              </Td>  
            </Tr>
          </Tbody>
        </Table>
      );
    } else if (statsType === 'Pitching' && pitchingStats) {
      const ps = pitchingStats;
      return (
        <Table variant="simple">
          <Thead>
            <Tr bg="gray.100">
              <Tooltip label="Pitching Games" hasArrow>
                <Th>G</Th>
              </Tooltip>
              <Tooltip label="Wins" hasArrow>
                <Th>W</Th>
              </Tooltip>
              <Tooltip label="Losses" hasArrow>
                <Th>L</Th>
              </Tooltip>
              <Tooltip label="Saves" hasArrow>
                <Th>SV</Th>
              </Tooltip>
              <Tooltip label="Innings Pitched" hasArrow>
                <Th>IP</Th>
              </Tooltip>
              <Tooltip label="Strikes" hasArrow>
                <Th>STR</Th>
              </Tooltip>
              <Tooltip label="Balls" hasArrow>
                <Th>BALL</Th>
              </Tooltip>
              <Tooltip label="Strikeouts" hasArrow>
                <Th>K</Th>
              </Tooltip>
              <Tooltip label="Runs Allowed" hasArrow>
                <Th>R</Th>
              </Tooltip>
              <Tooltip label="Earned Run Average" hasArrow>
                <Th>ERA</Th>
              </Tooltip>
              <Tooltip label="Walks" hasArrow>
                <Th>BB</Th>
              </Tooltip>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{ps.G ?? 'N/A'}</Td>
              <Td>{ps.W ?? 'N/A'}</Td>
              <Td>{ps.L ?? 'N/A'}</Td>
              <Td>{ps.SV ?? 'N/A'}</Td>
              <Td>
                {ps.IP !== undefined && ps.IP !== null
                  ? Number(ps.IP).toFixed(2)
                  : 'N/A'}
              </Td>   
              <Td>{ps.STR ?? 'N/A'}</Td>
              <Td>{ps.BALL ?? 'N/A'}</Td>
              <Td>{ps.K ?? 'N/A'}</Td>
              <Td>{ps.R ?? 'N/A'}</Td>
              <Td>
                {ps.ERA !== undefined && ps.ERA !== null
                  ? Number(ps.ERA).toFixed(2)
                  : 'N/A'}
              </Td>   
              <Td>{ps.BB ?? 'N/A'}</Td>
            </Tr>
          </Tbody>
        </Table>
      );
    } else if (statsType === 'Fielding' && fieldingStats) {
      const fs = fieldingStats;
      return (
        <Table variant="simple">
          <Thead>
            <Tr bg="gray.100">
              <Tooltip label="Fielding Games" hasArrow>
                <Th>G</Th>
              </Tooltip>
              <Tooltip label="Throwing Errors" hasArrow>
                <Th>ET</Th>
              </Tooltip>
              <Tooltip label="Fielding Errors" hasArrow>
                <Th>EF</Th>
              </Tooltip>
              <Tooltip label="Errors" hasArrow>
                <Th>ERR</Th>
              </Tooltip>
              <Tooltip label="Putouts" hasArrow>
                <Th>PO</Th>
              </Tooltip>
              <Tooltip label="Assists" hasArrow>
                <Th>A</Th>
              </Tooltip>
              <Tooltip label="Stolen Bases Allowed" hasArrow>
                <Th>SBA</Th>
              </Tooltip>
              <Tooltip label="Caught Stealing" hasArrow>
                <Th>CS</Th>
              </Tooltip>
              <Tooltip label="Double Plays" hasArrow>
                <Th>DP</Th>
              </Tooltip>
              <Tooltip label="Passed Balls" hasArrow>
                <Th>PB</Th>
              </Tooltip>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{fs.G ?? 'N/A'}</Td>
              <Td>{fs.ET ?? 'N/A'}</Td>
              <Td>{fs.EF ?? 'N/A'}</Td>
              <Td>{fs.ERR ?? 'N/A'}</Td>
              <Td>{fs.PO ?? 'N/A'}</Td>
              <Td>{fs.A ?? 'N/A'}</Td>
              <Td>{fs.SBA ?? 'N/A'}</Td>
              <Td>{fs.CS ?? 'N/A'}</Td>
              <Td>{fs.DP ?? 'N/A'}</Td>
              <Td>{fs.PB ?? 'N/A'}</Td>
            </Tr>
          </Tbody>
        </Table>
      );
    } else {
      return <Text>No statistics available.</Text>;
    }
  };

  const renderPitcherStatsSection = () => {
    return (
      <>
        <Button 
          variant="solid" 
          colorScheme="blue" 
          fontWeight="bold"
          onClick={() => setShowPitcherTable(!showPitcherTable)} 
          mb={6}
          mt={6}
          width="100%" 
          display="flex"
          alignItems="center" 
          justifyContent="space-between" 
          textDecoration="none" 
          borderRadius="md" 
          boxShadow="md"
        >
          {showPitcherTable ? <ChevronUpIcon boxSize={6} /> : <ChevronDownIcon boxSize={6} />}
          <Text ml={2} fontSize="xl" flex="1" textAlign="left">Pitchers - MPH/Inning</Text>
        </Button>

        {showPitcherTable && syntheticStats.pitcherStats && syntheticStats.pitcherStats.length > 0 && (
          <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={2}>
            {syntheticStats.pitcherStats.map((stat, index) => (
              <GridItem key={index}>
                <Box 
                  bg="white" 
                  p={6} 
                  textAlign="center" 
                  borderRadius="md" 
                  border="1px solid #d1d5db"
                >
                  <Text fontSize="lg" fontWeight="bold">{stat.inning}</Text>
                  {stat.mph.map((speed, idx) => (
                    <Text key={idx} fontSize="md">{speed}</Text>
                  ))}
                </Box>
              </GridItem>
            ))}
          </Grid>
        )}
      </>
    );
  };

  if (loading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Text fontSize="xl" color="red.500">{error}</Text>
      </Flex>
    );
  }

  // Layout para pantallas pequeñas
  if (isLessThan960) {
    return (
      <Box bg="gray.100" minHeight="100vh" pt={8}>
        <Container maxW="container.sm">
          <Alert status="info" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" borderRadius="md" boxShadow="md" bg="white" mb={6}>
            <AlertIcon boxSize="40px" mr={0} />
            <Box>
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                Small screen detected!
              </Text>
              <Text fontSize="md">
                We noticed your device screen is smaller than 10 inches.  
                This page is best viewed on a larger screen or in landscape mode. 
              </Text>
            </Box>
          </Alert>

          <Box bg="white" p={4} borderRadius="5px" shadow="md" border="1px solid #d1d5db" mb={4}>
            <Flex alignItems="center" direction={{ base: 'column', sm: 'row' }}>
              <Image
                boxSize="130px"
                src={
                  isFrontImage 
                    ? `/images/VictorEvent/${playerInfo?.statsCode} F.jpg`
                    : `/images/VictorEvent/${playerInfo?.statsCode} B.jpg`
                }
                border="1px solid gray"
                alt={`${getFullName()}`}
                mr={6}
                borderRadius="5px"
                onClick={handleImageClick} 
                cursor="pointer"
                onError={(e) => {
                  const img = e.currentTarget as HTMLImageElement;
                  if (img.src.endsWith('.jpg')) {
                    img.src = img.src.replace('.jpg', '.JPG');
                  } else {
                    img.src = '/images/player_user6.jpg';
                  }
                }}
              />
              <Box textAlign={{ base: 'center', sm: 'left' }}>
                <Text fontSize="lg" fontWeight="bold">
                  {getFullName()}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  <Text as="span" fontWeight="bold">Positions:</Text> {playerInfo?.position || 'N/A'}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  <Text as="span" fontWeight="bold">Age:</Text> {playerInfo?.age || 'N/A'}
                </Text>
                <TeamDisplay team={measures?.team} />
              </Box>
            </Flex>
            <Flex justifyContent="center" alignItems="center" mt={2}>
              <Box position="relative" mr={2}>
                <Icon
                  as={IoShirtOutline}
                  w={7}
                  h={7}
                  sx={{
                    '& path': {
                      fill: 'white',
                      stroke: 'gray.700',
                      strokeWidth: '20px'
                    }
                  }}
                />
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  color="gray.700"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  lineHeight="1"
                >
                  {playerInfo?.uniformNumber ?? 'N/A'}
                </Text>
              </Box>
              <FlagIcon 
                code={playerInfo?.countryCode as FlagIconCode} 
                size={30} 
              />
              <Box ml={4}>
                <Text fontSize="xs" color="gray.600">{"STATS CODE"}</Text>
                <Text fontSize="md" fontWeight="bold">{playerInfo?.statsCode || 'N/A'}</Text>
              </Box>
            </Flex>
          </Box>

          <Box bg="white" p={4} borderRadius="md" boxShadow="md">
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Text fontSize="lg" fontWeight="bold">{statsType} Stats</Text>
              <Flex alignItems="center">
                <Text fontWeight="bold" mr={2}>Type:</Text>
                <Select
                  value={options.find(o => o.value === statsType)}
                  onChange={(option) => option && setStatsType(option.value as 'Batting' | 'Pitching' | 'Fielding')}
                  placeholder="None"
                  options={options.filter(o => availableStatsTypes.includes(o.value))}
                  styles={customSelectStyles}
                />
              </Flex>
            </Flex>
            <Box overflowX="auto" mb={4}>
              {renderStatsTable()}
            </Box>
            {/* Leyenda sobre tooltips (en inglés) */}
            <Text fontSize="sm" color="gray.500" mt={2}>
              Hover over the column headers to see the meaning of these acronyms.
            </Text>

            {/* Ranking */}
            {ranking && (
              <Box mt={6}>
                <Heading fontSize="md" mb={3} color="gray.600">Base Metrics | Ranking</Heading>
                <Table variant="simple" size="sm" colorScheme="blue">
                  <Thead bg="gray.100">
                    <Tr>
                      <Th>Base Metric</Th>
                      <Th>Player Ranking</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>60 Yards</Td>
                      <Td>{ranking.yards}</Td>
                    </Tr>
                    <Tr>
                      <Td>Pop Time</Td>
                      <Td>{ranking.popTime}</Td>
                    </Tr>
                    <Tr>
                      <Td>60ft Fastball</Td>
                      <Td>{ranking.sixtyFeet}</Td>
                    </Tr>
                    <Tr>
                      <Td>IF Velocity</Td>
                      <Td>{ranking.velocityIF}</Td>
                    </Tr>
                    <Tr>
                      <Td>OF Velocity</Td>
                      <Td>{ranking.velocityOF}</Td>
                    </Tr>
                    <Tr>
                      <Td>C Velocity</Td>
                      <Td>{ranking.velocityC}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>

          {playerInfo && playerInfo.position.includes('Acá irá P cuando se decida utilizar este elemento') && (
            <Box mt={6} bg="white" p={4} borderRadius="md" boxShadow="md">
              {renderPitcherStatsSection()}
            </Box>
          )}

          <Box textAlign="center" fontSize="sm" color="gray.600">
            <Text mb={2}>
              If you find any error, please contact us via email at <strong>support@statsdeportes.com</strong>
            </Text>
            <Text>
              Data provided by <strong>STATS</strong>. This page displays publicly available statistics collected by STATS about players in various sports.
            </Text>
            <Text>
              For more information, visit <a href="https://www.statsdeportes.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>STATSDeportes</a>.
            </Text>
          </Box>

        </Container>
      </Box>
    );
  }

  // Layout para pantallas grandes
  return (
    <Box bg="gray.100" minHeight="100vh" pt={6}>
      <Container maxW="90%" mt={2} maxWidth="1030px" minWidth="1030px">
        <Flex justify="space-between" align="center" mb={8}>
          <IconButton
            aria-label="Redirect to Stats"
            icon={<Logo height={60} width={60} />}
            variant="ghost"
            onClick={() => window.location.href = "https://www.statsdeportes.com"}
            isRound
          />
          <Text fontSize="l" fontWeight="bold" textAlign="right">
            Winter Showcase 2024
          </Text>
        </Flex>

        <Flex mb={2} align="center">
          <Flex align="center" flex="1">
            <Button
              background="#fff"
              onClick={() => navigate('/')}
              width="90px"
              height="40px"
              borderRadius="md"
              boxShadow="md"
              _hover={{ bg: "gray.100" }}
              mr={4}
            >
              <ChevronLeftIcon fontSize="3xl" />
              <Text fontSize="lg" mr={3} fontWeight="bold">Back</Text>
            </Button>
          </Flex>
          <Flex flex="1" justify="center">
            <Heading textAlign="center" fontSize="24px" fontWeight="bold">
              Victor Pellot Under Class
            </Heading>
          </Flex>
          <Box flex="1" />
        </Flex>

        <Grid templateColumns="57% 43%" gap={2} mb={2}>
          <Box 
            bg="white" 
            p={4} 
            borderRadius="5px" 
            shadow="md" 
            height="166px"
            border="1px solid #d1d5db" 
            position="relative"
          >
            <Flex alignItems="center">
              <Image
                boxSize="133px"
                src={
                  isFrontImage 
                    ? `/images/VictorEvent/${playerInfo?.statsCode} F.jpg` 
                    : `/images/VictorEvent/${playerInfo?.statsCode} B.jpg`
                }
                alt={`${getFullName()}`}
                mr={{ base: 0, sm: 6 }}
                mb={{ base: 3, sm: 0 }}
                borderRadius="md"
                border="1px solid gray"
                objectFit="cover"
                onClick={handleImageClick} 
                cursor="pointer"
                onError={(e) => {
                  const img = e.currentTarget as HTMLImageElement;
                  if (img.src.endsWith('.jpg')) {
                    img.src = img.src.replace('.jpg', '.JPG');
                  } else {
                    img.src = '/images/player_user6.jpg';
                  }
                }}
              />
              <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                  {getFullName()}
                </Text>
                <Text fontSize="md" color="gray.600">
                  <Text as="span" fontWeight="bold">Positions:</Text> {playerInfo?.position || 'N/A'}
                </Text>
                <Text fontSize="md" color="gray.600">
                  <Text as="span" fontWeight="bold">Age:</Text> {playerInfo?.age || 'N/A'}
                </Text>
                <TeamDisplay team={measures?.team} />
              </Box>
            </Flex>

            <Box 
              position="absolute" 
              top={6} 
              right={6} 
              display="flex" 
              alignItems="center"
            >
              <Box position="relative" mr={2} display="flex" alignItems="center">
                <Icon
                  as={IoShirtOutline}
                  w={9}
                  h={9}
                  sx={{
                    '& path': {
                      fill: 'white',
                      stroke: 'gray.700',
                      strokeWidth: '20px'
                    }
                  }}
                  verticalAlign="middle"
                />
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  color="gray.700"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  lineHeight="1"
                >
                  {playerInfo?.uniformNumber ?? 'N/A'}
                </Text>
              </Box>
              <Box display="flex" alignItems="center" verticalAlign="middle">
                <FlagIcon 
                  code={playerInfo?.countryCode as FlagIconCode} 
                  size={40} 
                  style={{ verticalAlign: 'middle' }} 
                />
              </Box>
            </Box>

            <Box position="absolute" bottom={6} right={6} textAlign="right">
              <Text fontSize="10px" mr={4}>{"STATS CODE"}</Text>
              <Text fontSize="xl" fontWeight="bold">{playerInfo?.statsCode || 'N/A'}</Text>
            </Box>
          </Box>

          <Box bg="transparent" width="423px">
            <Grid templateColumns="repeat(3, 1fr)" gap={2} mb={2}>
              <Box 
                bg="white" 
                p={2} 
                textAlign="center" 
                borderRadius="md" 
                border="1px solid #d1d5db" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                shadow="md"
                height="79px"
              >
                <Text fontSize="md" fontWeight="bold">60 Yards</Text>
                <Text as="span" fontSize="xl" fontWeight="bold" color="gray.500" ml={1}>
                  {measures?.yards || 'N/A'}
                  <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">sec</Text>
                </Text>
              </Box>

              <Box 
                bg="white" 
                p={2} 
                textAlign="center" 
                borderRadius="md" 
                border="1px solid #d1d5db" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                shadow="md"
                height="79px"
              >
                <Text fontSize="md" fontWeight="bold">Pop Time</Text>
                <Text as="span" fontSize="xl" fontWeight="bold" color="gray.500" ml={1}>
                  {measures?.popTime || 'N/A'}
                  <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">sec</Text>
                </Text>
              </Box>

              <Box 
                bg="white" 
                p={2} 
                textAlign="center" 
                borderRadius="md" 
                border="1px solid #d1d5db" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                shadow="md"
                height="79px"                  
              >
                <Text fontSize="md" fontWeight="bold">60ft Fastball</Text>
                <Text as="span" fontSize="xl" fontWeight="bold" color="gray.500" ml={1}>
                  {measures?.sixtyFeet || 'N/A'}
                  <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">mph</Text>
                </Text>
              </Box>
            </Grid>

            <Box 
              bg="white" 
              p={4} 
              borderRadius="md" 
              border="1px solid #d1d5db" 
              textAlign="left"
              shadow="md"
              height="79px"
            >
              <Text fontSize="lg" fontWeight="bold">Velocity</Text>
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <Text as="span" fontSize="md" fontWeight="bold">IF </Text>{' '}
                  <Text as="span" fontSize="lg" fontWeight="bold" color="gray.500" ml={1}>
                    {measures?.velocity.IF || 'N/A'}
                    <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">mph</Text>
                  </Text>
                </GridItem>

                <GridItem>
                  <Text as="span" fontSize="md" fontWeight="bold">OF </Text>{' '}
                  <Text as="span" fontSize="lg" fontWeight="bold" color="gray.500" ml={1}>
                    {measures?.velocity.OF || 'N/A'}
                    <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">mph</Text>
                  </Text>
                </GridItem>

                <GridItem>
                  <Text as="span" fontSize="md" fontWeight="bold">C </Text>{' '}
                  <Text as="span" fontSize="lg" fontWeight="bold" color="gray.500" ml={1}>
                    {measures?.velocity.C || 'N/A'}
                    <Text as="span" fontSize="sm" verticalAlign="super" fontWeight="normal" ml={1} color="gray.500">mph</Text>
                  </Text>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Box bg="white" p={6} borderRadius="md" shadow="md" mb={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Text fontSize="xl" fontWeight="bold">{statsType} Stats</Text>
            <Flex alignItems="center">
              <Text fontWeight="bold" mr={2}>Type:</Text>
              <Select
                value={options.find(o => o.value === statsType)}
                onChange={(option) => option && setStatsType(option.value as 'Batting' | 'Pitching' | 'Fielding')}
                placeholder="None"
                options={options.filter(o => availableStatsTypes.includes(o.value))}
                styles={customSelectStyles}
              />
            </Flex>
          </Flex>
          <Box overflowX="auto">
            {renderStatsTable()}
          </Box>
          {/* Leyenda sobre tooltips (en inglés) */}
          <Text fontSize="sm" color="gray.500" mt={2}>
            Hover over the column headers to see the meaning of these acronyms.
          </Text>
        </Box>

        {/* Ranking */}
        <Box bg="white" p={6} borderRadius="md" shadow="md" mb={8} mt={2}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>Player Ranking</Text>
          {ranking ? (
            <Box maxW="100%" mx="auto">
              <Table variant="simple" colorScheme="gray" size="md">
                <Thead bg="gray.100">
                  <Tr>
                    <Th>Base Metrics</Th>
                    <Th>Ranking</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>60 Yards</Td>
                    <Td fontWeight="bold">{ranking.yards}</Td>
                  </Tr>
                  <Tr>
                    <Td>Pop Time</Td>
                    <Td fontWeight="bold">{ranking.popTime}</Td>
                  </Tr>
                  <Tr>
                    <Td>60ft Fastball</Td>
                    <Td fontWeight="bold">{ranking.sixtyFeet}</Td>
                  </Tr>
                  <Tr>
                    <Td>IF Velocity</Td>
                    <Td fontWeight="bold">{ranking.velocityIF}</Td>
                  </Tr>
                  <Tr>
                    <Td>OF Velocity</Td>
                    <Td fontWeight="bold">{ranking.velocityOF}</Td>
                  </Tr>
                  <Tr>
                    <Td>C Velocity</Td>
                    <Td fontWeight="bold">{ranking.velocityC}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text>No ranking data available.</Text>
          )}
        </Box>

        {playerInfo && playerInfo.position.includes('Acá irá P cuando se decida utilizar este elemento') && (
          <Box mt={6} bg="white"  borderRadius="md" boxShadow="md">
            {renderPitcherStatsSection()}
          </Box>
        )}

        <Box textAlign="center" fontSize="sm" color="gray.600" bg="gray.100">
          <Text>
            If you find any error, please contact us via email at <strong>support@statsdeportes.com</strong>
          </Text>
          <Text>
            Data provided by <strong>STATS</strong>. This page displays publicly available statistics collected by STATS about players in various sports.
          </Text>
          <Text>
            For more information, visit <a href="https://www.statsdeportes.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>STATSDeportes</a>.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default PlayerStatsPage;
